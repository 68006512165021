import React, { Component, useState, useEffect } from 'react';
import Hamburger from 'hamburger-react'

import ButtonJoined from './ButtonJoined';
import ButtonBack from './ButtonBack';

import { useSelector, useDispatch } from 'react-redux';
import {updateHouse} from '../../../../redux/actions/houseActions'
import {updateUserFrame} from '../../../../redux/actions/userFrameActions'
import ToggleSwitch from './Toggle';

function Header({}) {

  const userFrame = useSelector(state=>state.user_frame_reducer[0])
  const houses = useSelector(state=>state.house_reducer)
  const dispatch = useDispatch()

  function menuHasBeenClicked(){
    const frame = userFrame;
    frame.menu = !frame.menu
    dispatch(updateUserFrame(frame))
  }

  return (

    <div style = {{width: '100%',height:'100%',backgroundColor:userFrame.darkMode?'#2A2C2E':'white', display:'flex', alignItems:'center',justifyContent:'center' }}>
      
        {/* Back NAVIGATION */}
        <div style={{display: 'inline-block',width:'15%', backgroundColor:userFrame.darkMode?'#2A2C2E':'white', zIndex:11,}}>
          {userFrame.menu || (userFrame.leftHandedDrawer && userFrame.drawer == 1 && userFrame.tabView==2)?
              <></>
            :
              <ButtonBack/>
          }
        </div>

        {userFrame.menu?
          /* Show Menu */
          <div style={{ backgroundColor:userFrame.darkMode?'#2A2C2E':'white', height: '100%', overflowY:'hidden',width:'70%', display:'flex', alignItems:'center',justifyContent:'center'}}>
              <h1 style={{color:userFrame.darkMode?'white':'#4B286D', fontSize:'27px'}}>Menu</h1>
          </div>
        :
          /*  View Button Selection  */
          <div style={{ backgroundColor:userFrame.darkMode?'#2A2C2E':'white', height: '100%', overflowY:'hidden',width:'70%', display:'flex', alignItems:'center',justifyContent:'space-evenly'}}>
              <ButtonJoined/>
              <div style={{width: '100px', height: '100%', display:'flex', alignItems:'center',justifyContent:'center'}}>
                <ToggleSwitch darkMode={userFrame.darkMode} disabled={userFrame.houseId == 0}/>
              </div>
          </div>
        }
        
        {/*  Menu Button  */}
        {userFrame.drawer==1 && userFrame.tabView == 2?
        <></>
        :
        <div style={{ backgroundColor:userFrame.darkMode?'#2A2C2E':'white', height: '100%', overflow:'hidden', width:'15%', display:'inline-block',}}>
          <div style={{display:'flex', alignItems:'center',justifyContent:'center', height:'100%', width:'100%'}}>
            <Hamburger color={userFrame.darkMode?'white':'#4B286D'} toggle={menuHasBeenClicked} toggled={userFrame.menu}/>
          </div>
        </div>
        }  
    </div>

  );
}

export default Header;

import React, { useEffect, useState, useRef } from "react";

import greenHouse from '../../../../icons/house/green/house.png';
import purpleHouse from '../../../../icons/house/purple/house.png';
import whiteHouse from '../../../../icons/house/white/house.png';
import greyHouse from '../../../../icons/house/grey/house.png';

import greenNOH from '../../../../icons/house/green/not-home.png';
import purpleNOH from '../../../../icons/house/purple/not-home.png';
import whiteNOH from '../../../../icons/house/white/not-home.png';
import greyNOH from '../../../../icons/house/grey/not-home.png';

import greenSN from '../../../../icons/house/green/soft-no.png';
import purpleSN from '../../../../icons/house/purple/soft-no.png';
import whiteSN from '../../../../icons/house/white/soft-no.png';
import greySN from '../../../../icons/house/grey/soft-no.png';

import greenHN from '../../../../icons/house/green/hard-no.png';
import purpleHN from '../../../../icons/house/purple/hard-no.png';
import whiteHN from '../../../../icons/house/white/hard-no.png';
import greyHN from '../../../../icons/house/grey/hard-no.png';

import greenWP from '../../../../icons/house/green/wrong-party.png';
import purpleWP from '../../../../icons/house/purple/wrong-party.png';
import whiteWP from '../../../../icons/house/white/wrong-party.png';
import greyWP from '../../../../icons/house/grey/wrong-party.png';

import greenNI from '../../../../icons/house/green/not-interested.png';
import purpleNI from '../../../../icons/house/purple/not-interested.png';
import whiteNI from '../../../../icons/house/white/not-interested.png';
import greyNI from '../../../../icons/house/grey/not-interested.png';

import greenOK from '../../../../icons/house/green/other-knock.png';
import purpleOK from '../../../../icons/house/purple/other-knock.png';
import whiteOK from '../../../../icons/house/white/other-knock.png';
import greyOK from '../../../../icons/house/grey/other-knock.png';

import greenSK from '../../../../icons/house/green/successful-knock.png';
import purpleSK from '../../../../icons/house/purple/successful-knock.png';
import whiteSK from '../../../../icons/house/white/successful-knock.png';
import greySK from '../../../../icons/house/grey/successful-knock.png';

import greenFU from '../../../../icons/house/green/follow-up.png';
import purpleFU from '../../../../icons/house/purple/follow-up.png';
import whiteFU from '../../../../icons/house/white/follow-up.png';
import greyFU from '../../../../icons/house/grey/follow-up.png';

import blue from '../../../../icons/house/blue/pending-order.png';
import red from '../../../../icons/house/red/do-not-knock.png';


import Card from '@tds/core-card'
import Box from "@tds/core-box";
import Heading from "@tds/core-heading";
import Button from "@tds/core-button";

import { useSelector, useDispatch } from 'react-redux';
import {updateHouse} from '../../../../redux/actions/houseActions'
import {updateUserFrame} from '../../../../redux/actions/userFrameActions'

function ButtonHouse({icon, title, titleTwo, extra}) {

    const userFrame = useSelector(state=>state.user_frame_reducer[0])
    const houses = useSelector(state=>state.house_reducer)
    const dispatch = useDispatch()

    function captureLead(){
      const u = userFrame
      u.softNoOverlay = false;
      dispatch(updateUserFrame(u))
    }

    function confirmSelection() {

      if(icon == 'OK'){
        // show other knocks: set vars
        const u = userFrame
        u.loggingKnock = true
        // close the follow up screen if open
        u.followingUp = false
        // close the details screen if open
        u.showDetails = false
        u.drawer = 1
        dispatch(updateUserFrame(u))
      }else if(icon == 'SN' && userFrame.softNoOverlay == false){
      
        const u = userFrame
        u.softNoOverlay = true
        dispatch(updateUserFrame(u))
      
      }else{
        // set the 'confirm box' variables to display the 'ConfirmBox' component in map view
        const u = userFrame
        u.confirmBox = true
        u.confirmAddress = houses[userFrame.houseId-1].address
        u.confirmKnockType = title
        u.confirmQuickKnock = true;
        u.confirmIcon = icon;
        u.softNoOverlay = false;
        dispatch(updateUserFrame(u))
      }
  
    }

  const iconWidth = window.innerWidth>700? '40px':'30px'

  function renderIcon(selection) {
    switch(selection) {
      case 'NOH':
        if(userFrame.darkMode){
          return <img src={whiteNOH} width={iconWidth} />;
        }else{
          return <img src={purpleNOH} width={iconWidth} />;
        }
      case 'SN':
        if(userFrame.darkMode){
          return <img src={whiteSN} width={iconWidth} />;
        }else{
          return <img src={purpleSN} width={iconWidth} />;
        }
      case 'HN':
        if(userFrame.darkMode){
          return <img src={whiteHN} width={iconWidth} />;
        }else{
          return <img src={purpleHN} width={iconWidth} />;
        }      
      case 'WP':
        if(userFrame.darkMode){
          return <img src={whiteWP} width={iconWidth} />;
        }else{
          return <img src={purpleWP} width={iconWidth} />;
        }
      case 'NI':
        if(userFrame.darkMode){
          return <img src={whiteNI} width={iconWidth} />;
        }else{
          return <img src={purpleNI} width={iconWidth} />;
        }      
      case 'OK':
        if(userFrame.darkMode){
          return <img src={whiteOK} width={iconWidth} />;
        }else{
          return <img src={purpleOK} width={iconWidth} />;
        }      
      case 'house':
        if(userFrame.darkMode){
          return <img src={whiteHouse} width={iconWidth} />;
        }else{
          return <img src={purpleHouse} width={iconWidth} />;
        }
      case 'SK':
        if(userFrame.darkMode){
          return <img src={whiteSK} width={iconWidth} />;
        }else{
          return <img src={purpleSK} width={iconWidth} />;
        }
      case 'FU':
        if(userFrame.darkMode){
          return <img src={whiteFU} width={iconWidth} />;
        }else{
          return <img src={purpleFU} width={iconWidth} />;
        }  
      case 'pending': 
        return <img src={blue} width={iconWidth} />;
      case 'DNK':
        return <img src={red} width={iconWidth} />;

      default:
        if(userFrame.darkMode){
          return <img src={whiteHouse} width={iconWidth} />;
        }else{
          return <img src={purpleHouse} width={iconWidth} />;
        }     
      }
  }


  const refClickOutside = useRef(null)

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
        document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const handleClickOutside = (event) => {
      if (refClickOutside.current && !refClickOutside.current.contains(event.target)) {
          const u = userFrame
          u.softNoOverlay = false
          dispatch(updateUserFrame(u))
      }
  };

  function softNoOverlay() {
    if(userFrame.softNoOverlay && icon == 'SN'){
      return(
        <div ref={refClickOutside}>
          <div style={{position:'absolute', top:'15%', left:'0', marginTop: "90px", marginLeft:'27%', transform:'translateX(-50%)' ,width:'30px', height:'30px', zIndex:5, overflow:'hidden' }}>
            <div style={{position:'relative', transform:'translateY(-70%) rotate(45deg)' ,width:'30px', height:'30px', backgroundColor:userFrame.darkMode?'#54595F':'white', zIndex:5,boxShadow: userFrame.darkMode?'none':'0 1px 2px 2px #9E9E9E' }}>
            </div>
          </div>
          <div style={{position:'absolute', top:'15%', left:'0', marginLeft:'27%', transform:'translateX(-50%)' ,width:'170px', height:'90px', backgroundColor:userFrame.darkMode?'#54595F':'white', borderRadius:'3px',zIndex:4, boxShadow:userFrame.darkMode?'none':'0 1px 2px 2px #9E9E9E'}}>
          </div>
          <div style={{position:'absolute', top:'15%', left:'0', marginLeft:'27%', transform:'translateX(-50%)' ,width:'170px', height:'90px', backgroundColor:userFrame.darkMode?'#54595F':'white', borderRadius:'3px',zIndex:6, }}>
            <button onClick={()=>{captureLead()}} style={{width:'90%', height:'30px', marginLeft:'5%',marginTop:'10px', backgroundColor:userFrame.darkMode?'#D8D8D8':'#2B8000', borderRadius:'3px', border:'none', color:userFrame.darkMode?'black':'white'}}>
              Capture Lead
            </button>
            <button onClick={()=>{confirmSelection()}} style={{width:'90%', height:'30px', marginLeft:'5%', marginTop:'10px', backgroundColor:userFrame.darkMode?'#D8D8D8':'#4B286D', borderRadius:'3px', border:'none', color:userFrame.darkMode?'black':'white'}}>
              Soft No
            </button>
          </div>
          </div>
      )
    }
  }

  return (
      <>
      {softNoOverlay()}

      <button
        style={{ 
        height:'100%', width:'100%',
        color: userFrame.darkMode?'white':'#4B286D', fontWeight: houses[userFrame.houseId-1].icon==icon?'900':'400', borderColor:userFrame.darkMode?'#2A2C2E':'white', 
        borderRadius:'3px', borderWidth: '3px', 
        borderStyle: 'solid', backgroundColor:userFrame.darkMode?'#2A2C2E': 'white', alignItems:'center', justifyContent:'center', display:'flex' }}

        onClick={() => confirmSelection()}
      >
        <div>
          {renderIcon(icon)}
          <h1 style={{fontSize:'11px', marginTop:'-3px'}}>{title}{" "}{extra?<span style={{color:userFrame.darkMode?'#2A2C2E':'white', display:'block'}}>Status</span>:<span style={{ display:'block'}}>{titleTwo}</span>}</h1>
        </div>
      </button>

      </>
  );
}

export default ButtonHouse;
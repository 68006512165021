import React, { useEffect, useRef, useState, } from "react";
import { useSelector, useDispatch } from "react-redux";

import Hamburger from 'hamburger-react'
import { Checkmark, Times, NotificationSuccess } from '@tds/core-feedback-icon'
import { PikTV, Internet, Tv, HomeSecurity, OnlineSecurity, SecurityHouse, CallTalking, Phone, Heartbeat, Delivery, Deals, ArrowUp, Clipboard, Calendar, WifiBoost, Mobility, Call, LockOpened, Gift, Time, Shop, Devices, PiggyBank, SecurityCamera, DataLimit, Channels, CallOut, Information, Watch, CreditCard, Preference, Attention, ThumbsUp, Document } from '@tds/core-decorative-icon'
import arrowDownP from "../../../icons/arrow/down/purple.png"
import arrowDownW from "../../../icons/arrow/down/white.png"
import arrowUpW from "../../../icons/arrow/up/white.png"
import arrowUp from "../../../icons/arrow/up/purple.png"
import arrowUpP from "../../../icons/arrow/up/purple.png"
import alpaca from "../../../icons/images/alpaca.png"
import plus from "../../../icons/plus/white/plus.png"
import plusP from "../../../icons/plus/purple/plus.png"
import minus from '../../../icons/minus/white.png'
import minusP from '../../../icons/minus/purple.png'
import DimpleDivider from '@tds/core-dimple-divider'

import { updateHouse } from '../../../redux/actions/houseActions'
import { updateUserFrame } from '../../../redux/actions/userFrameActions'
import HairlineDivider from "@tds/core-hairline-divider";
import { Subtract } from "@tds/core-interactive-icon";
import ShowDetails from "../../MapView/TabletDesktop/SubComponents/ShowDetails";
import ButtonGroup from "@tds/core-button-group";
import ButtonJoined from "./ButtonJoined";
import ProductCheckBox from "./ProductCheckBox";
import CommitmentCheckbox from "./CommitmentCheckBox";
import FlowIndicator from "./FlowIndicator";
import OfferCheckBox from "./OfferCheckBox";

function ProductsCommitmentsOffers() {

    const userFrame = useSelector(state => state.user_frame_reducer[0])
    const houses = useSelector(state => state.house_reducer)
    const dispatch = useDispatch()

    let [width, setWidth] = useState(window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth);

    let [height, setHeight] = useState(window.innerHeight
        || document.documentElement.clientHeight
        || document.body.clientHeight);

    /*
        Component did mount
    */
    useEffect(() => {

        function resizeListener() {
            const getWidth = window.innerWidth
                || document.documentElement.clientWidth
                || document.body.clientWidth;

            setWidth(getWidth)

            const getHeight = window.innerHeight
                || document.documentElement.clientHeight
                || document.body.clientHeight;

            setHeight(getHeight)
        };

        // set resize listener
        window.addEventListener('resize', resizeListener);

        // clean up function
        return () => {
            // remove resize listener
            window.removeEventListener('resize', resizeListener);
        }
    }, [])

    /*
    Component did update
    */
    useEffect(() => {

    })

    function expandOffers(product) {

        const u = userFrame

        u.products.forEach((p) => {
            if (p.type == product.type) {
                p.expand = !p.expand;
            }
        })
        dispatch(updateUserFrame(u));

    }

    function checkProducts() {

        var productSelected = false;
        userFrame.products.forEach((prod) => {
            if (prod.product == true) {
                productSelected = true;
            }
        })
        return productSelected;
    }

    function checkCommitment() {

        var commitmentSelected = false;
        var productSelected = null;
        userFrame.products.forEach((prod) => {
            if (prod.product == true) {
                productSelected = prod;
            }
        })

        if (productSelected != null && productSelected.commitment != '') {
            commitmentSelected = true;
        }

        return commitmentSelected;

    }

    function checkOffers() {

        return false

    }

    function pageIsUnlocked(page) {
        var canProceed = false;

        switch (page) {
            case 0:
                canProceed = true
                break;
            case 1:
                canProceed = checkProducts()
                // reset
                break;
            case 2:
                canProceed = checkCommitment()
                // reset selections on bundle
                break;
            case 3:
                canProceed = checkOffers()
                break;
            case 4:

                break;
            case 5:

                break;

        }
        return canProceed;
    }

    function onChangeFlowPage(page) {
        const u = userFrame;
        var orderFlowPages = u.orderFlowPages;
        var progressionStopped = false;

        var canProceed = pageIsUnlocked(page)

        if (canProceed) {
            for (var i = 0; i < orderFlowPages.length; i++) {
                // change first set of values to green, up to page clicked
                if (progressionStopped == false) {
                    orderFlowPages[i] = 1;
                } else {
                    orderFlowPages[i] = 0;
                }
                // change the rest to white
                if (i == page) {
                    progressionStopped = true;
                }
            }
            u.page = page
            u.orderFlowPages = orderFlowPages;
            dispatch(updateUserFrame(u))
        }

    }

    function onCheck(product) {

        product.product = !product.product

        const u = userFrame
        u.products.forEach(p => {
            if (p.type == product.type) {
                p = product
            }
            p.commitment = ''
            p.expand = true
        });

        dispatch(updateUserFrame(u))
    }

    function numberOfProductsSelected() {
        const u = userFrame;
        var products = u.products;
        var counter = 0;
        products.forEach(p => {
            if (p.product == true) {
                counter++;
            }
        })

        return counter
    }

    function showDetails(value, id) {
        const u = userFrame
        u.showProductDetails = value;
        u.offerDetailsToShow = id;
        dispatch(updateUserFrame(u))
    }

    function exitHasBeenClicked() {

        const u = userFrame
        u.showPCO = false;
        dispatch(updateUserFrame(u))

    }

    function containsProduct(product) {
        const offers = houses[userFrame.houseId - 1].offer;
        var contains = false
        offers.length > 0 && offers.forEach((o, i) => {
            //check if there is an offer selected for this product
            if (o.product == product.type) {
                contains = true
            }
        })
        return contains
    }

    function viewCartDetails() {
        const u = userFrame
        u.viewCartDetails = !u.viewCartDetails
        dispatch(updateUserFrame(u))
    }

    function getMaxPromoSavings(product) {

        var maxPromoSavings = 0;

        product.offers.forEach(offer => {
            var savings = offer.promoDiscount
            if (savings > maxPromoSavings && savings != 'none') {
                maxPromoSavings = savings
            }
        })

        return maxPromoSavings
    }

    function getMaxTermSavings(product) {

        var maxTermSavings = 0;

        product.offers.forEach(offer => {
            var savings = offer.priceNoTerm - offer.price;
            if (savings > maxTermSavings) {
                maxTermSavings = savings
            }
        })

        return maxTermSavings
    }

    function calculateTotal() {
        var total = 0
        userFrame.products.forEach(product => {
            product.offers.forEach(offer => {
                if (offer.id == product.offer) {
                    total = parseInt(total) + parseInt(product.commitment == 'No term' ? offer.priceNoTerm : (product.promoActivated && offer.promoDiscount != 'none' ? offer.pricePromo : offer.price))
                }
            })
        })
        return total
    }

    function getCostForOffer(id) {
        var total = 0
        userFrame.products.forEach(product => {
            product.offers.forEach(offer => {
                if (offer.id == id) {
                    total = parseInt(product.commitment == 'No term' ? offer.priceNoTerm : (product.promoActivated && offer.promoDiscount != 'none' ? offer.pricePromo : offer.price))
                }
            })
        })
        return total
    }

    function onChangePromoTab(value, product) {
        const u = userFrame;
        var products = u.products;
        products.forEach(p => {
            if (p.type == product.type) {
                p.promoTab = value
            }
        })
        u.products = products;
        dispatch(updateUserFrame(u))
    }

    function removeOffer(id) {

        const h = houses[userFrame.houseId - 1]
        var offers = houses[userFrame.houseId - 1].offer
        var index
        offers.forEach((o, i) => {
            if (o.id == id) {
                index = i
            }
        })
        if (offers.length > 1) {
            offers.splice(index, 1);
        } else {
            offers = []
        }
        h.offer = offers
        dispatch(updateHouse(h))

        const u = userFrame
        var products = u.products
        products.forEach((p) => {
            if (p.offer == id) {
                p.offer = 0
                p.commitment = ''
                p.product = false

                onChangePromoTab("offer", p)
            }
        })
        u.products = products
        dispatch(updateUserFrame(u))
        deActivatePromos()

    }

    function clearCartOfThisProduct(product) {

        // search the checkout if the offer/product is already selected
        var foundOffer = -1
        var offers = houses[userFrame.houseId - 1].offer
        // go through each offer in this houses cart
        offers != null && offers.length > 0 && offers.forEach((o, i) => {
            //check if there is an offer for this product
            if (o.product == product.type) {
                foundOffer = i
            }
        })
        // if an offer exists for the product
        if (foundOffer != -1) {
            if (offers.length > 1) {
                offers.splice(foundOffer, 1); // remove it or 
            } else {
                offers = []; // empty the array if 1 item left
            }
        }

        // update redux
        var h = houses[userFrame.houseId - 1]
        h.offer = offers
        dispatch(updateHouse(h))
    }

    function selectRowProduct(p, justExpandCollapse) {

        if (p.product && p.offer != 0) {
            removeOffer(p.offer)
        } else {
            const u = userFrame
            // for each product
            u.products.forEach((product) => {
                // is this the product in question
                if (product.type == p.type) {

                    if (!justExpandCollapse && product.product) {

                        product.offer = 0

                        clearCartOfThisProduct(product)
                    }
                    product.product = !product.product
                }

                //product.offer = 0;
                //product.commitment = ''
                product.expand = true

            })
            dispatch(updateUserFrame(u))
        }
        deActivatePromos()

    }

    function selectRowCommitment(product, term) {
        const u = userFrame
        u.products.forEach((prod) => {
            if (product.type == prod.type) {
                if (product.commitment == term) {
                    product.commitment = ''
                    product.offer = 0
                    clearCartOfThisProduct(product)
                } else {
                    product.commitment = term
                }
                clearCartOfThisProduct(product)
                product.offer = 0
                product.expand = true
            }
        })
        dispatch(updateUserFrame(u))
        deActivatePromos()
    }

    function selectRowOffer(offer, p) {

        /*** FOR THE CART ***/
        // save offer, commitment, and product to specific-house checkout
        const h = houses[userFrame.houseId - 1]
        var offers = h.offer
        var foundOffer = -1
        var sameOffer = false

        // search the checkout if the offer/product is already selected
        offers != null && offers.length > 0 && offers.forEach((o, i) => {
            if (o.id == offer) {
                sameOffer = true
            }

            if (o.product == p.type) {
                foundOffer = i
            }
        })

        // if no products in checkout match the selected offer
        if (foundOffer == -1) {
            // add it to the checkout
            offers.push({
                id: offer,
                price: p,
                commitment: p.commitment,
                product: p.type,
            })

            // else that offer already exists
        } else {

            if (offers.length > 1) {
                // offer is being unselected
                if (sameOffer) {
                    // remove it
                    offers.splice(foundOffer, 1);

                    // offer is replacing another offer from this product
                } else {
                    // remove it
                    offers.splice(foundOffer, 1);
                    // replace it
                    offers.push({
                        id: offer,
                        price: p,
                        commitment: p.commitment,
                        product: p.type,
                    })
                }

            } else {
                if (sameOffer) {
                    offers = []
                } else {
                    offers = [{
                        id: offer,
                        price: p,
                        commitment: p.commitment,
                        product: p.type,
                    },]
                }
            }

            // unselect 'bundles' tab
            onChangePromoTab("offer", p)
        }

        h.offer = offers
        dispatch(updateHouse(h))
        /*** END FOR THE CART ***/

        /*** FOR THE STATE MGNT ***/
        // save offer for state mgnt (viewing selected offers)
        const u = userFrame
        var removing = false;
        u.products.forEach((product) => {
            if (product.type == p.type) {
                if (product.offer == offer) {
                    product.offer = 0
                    removing = true
                } else {
                    product.offer = offer
                    expandOffers(p)
                }
            }
        })

        dispatch(updateUserFrame(u))
        deActivatePromos()
    }

    function deActivatePromos() {

        // show discount prices of everything (promoActivated, $10 off), except last item in cart
        const u = userFrame
        const h = houses[userFrame.houseId - 1]

        var quantity
        if (h.offer != null) {
            quantity = h.offer.length
        }
        u.products.forEach((p) => {
            // if theres an intem in the cart
            if (h.offer != null && quantity > 0) {
                // if this offer is selected
                if (p.offer != 0) {
                    // if its not the last item in cart
                    if (p.offer == h.offer[0].id) {
                        p.promoActivated = false
                    } else {
                        // last item in cart, dont activate promo
                        p.promoActivated = true
                    }
                } else {
                    // activate unselected offers
                    p.promoActivated = true
                }
            } else {
                // no promos to show for not offers selected
                p.promoActivated = false
            }
        })

        /* Error alert
        h.offer != null && h.offer.length>0 && h.offer.forEach((o,i)=>{
            if(i=0){
                o.price.promoActivated = false
            }else{
                o.price.promoActivated = true
            }
        })

        dispatch(updateUserFrame(u))
        dispatch(updateHouse(u))*/


    }

    function selectPromotionalOffer(prevProduct, nextProduct) {

        selectRowProduct(nextProduct, true); // open next desired offer
        //scrollToProduct(nextProduct.type); // scroll to it
        var term = prevProduct.commitment
        if (nextProduct.type == 'SHS' && term < 36) {
            term = '36'
        } else if (prevProduct.type == 'SHS' && term > 36) {
            term = '36'
        }
        selectRowCommitment(nextProduct, term, true); // select the same commitment
    }

    function renderAllPromos(p) {
        return (
            <div style={{ width: '100%', display: 'block', }}>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                    <div style={{ marginLeft: '10%' }}>
                        <ButtonJoined product={p} />
                    </div>
                </div>
                <div style={{ height: '300px', width: '100%', overflowX: 'scroll', overflowY: 'hidden', whiteSpace: 'nowrap', }}>

                    {/* gifts */}
                    {p.offers.filter((offer) => {
                        if (p.offer == offer.id) {
                            if (p.type == 'TV') {

                                var internet = userFrame.products.filter((prod) => {
                                    if (prod.type == "Internet") {
                                        return prod
                                    }
                                })

                                if (internet[0].offer != 0) {
                                    return offer
                                }
                            } else if (p.type == 'Internet') {

                                var tv = userFrame.products.filter((prod) => {
                                    if (prod.type == "TV") {
                                        return prod
                                    }
                                })

                                if (tv[0].offer != 0) {
                                    return offer
                                }
                            } else if (p.type != 'TV' && p.type != 'Internet') {

                                return offer
                            }
                        }
                    })
                        .map((offer, i) => {

                            return (
                                <div key={i}
                                    style={{
                                        height: '250px', borderRadius: '5px', width: '300px', marginLeft: '2.5%', marginRight: '2.5%', marginTop: '25px', boxShadow: userFrame.darkMode ? 'none' : ('0px 0px 7px 1px rgba(0,0,0,0.3)'),
                                        backgroundColor: userFrame.darkMode ? '#54595F' : 'white', display: 'inline-block', verticalAlign: 'top', whiteSpace: 'normal'
                                    }}>

                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', padding: '10px', }}>
                                        <div>

                                            <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', width: '100%', }}>
                                                {renderProductIcon("Gift", false, 32)}
                                                <h1 style={{ marginLeft: '10px', fontSize: '17px', color: userFrame.darkMode ? '#D8D8D8' : ("#4B286D") }}>
                                                    <span style={{ fontSize: '30px', fontWeight: 'bold', }}>Free</span>
                                                </h1>
                                            </div>

                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', width: '90%', marginLeft: "10%", marginTop: '10px' }}>
                                            {renderProductIcon(offer.giftIcon, false, 24)}
                                            <h1 style={{ marginLeft: '10px', textAlign: 'left', color: userFrame.darkMode ? '#D8D8D8' : ("#4B286D"), fontSize: 15, fontWeight: 'normal' }}>
                                                <span style={{ fontWeight: 'bold' }}>{offer.gift}</span>
                                            </h1>
                                        </div>

                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '50px', marginTop: '30px' }}>
                                            <button onClick={() => { }} style={{ width: '250px', height: '50px', backgroundColor: '#2B8000', borderRadius: '5px', outline: 'none', border: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <img src={plus} style={{ width: '35px', }} />
                                                <h1 style={{ color: 'white', fontWeight: 'bold', marginLeft: '5px' }}>
                                                    Add to your bundle
                                                </h1>
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            )
                        })}

                    {/* promos */}
                    {userFrame.products.filter((product) => {
                        if (product.type != p.type && !containsProduct(product)) {
                            return product
                        }
                    })
                        .map((product, i) => {

                            return (
                                <div key={i}
                                    style={{
                                        height: '250px', borderRadius: '5px', width: '300px', marginLeft: '2.5%', marginRight: '2.5%', marginTop: '25px', boxShadow: userFrame.darkMode ? 'none' : ('0px 0px 7px 1px rgba(0,0,0,0.3)'),
                                        backgroundColor: userFrame.darkMode ? '#54595F' : 'white', display: 'inline-block', verticalAlign: 'top', whiteSpace: 'normal'
                                    }}>

                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', padding: '10px', }}>
                                        <div>

                                            <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', width: '100%', }}>
                                                {renderProductIcon(product.type, false, 32)}
                                                <h1 style={{ marginLeft: '10px', fontSize: '17px', color: userFrame.darkMode ? '#D8D8D8' : ("#4B286D") }}>
                                                    <span style={{ fontSize: '30px', fontWeight: 'bold', }}>{product.type}</span>
                                                </h1>
                                            </div>

                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', width: '90%', marginLeft: "10%", marginTop: '10px' }}>
                                            {renderProductIcon("dollar-sign", false, 24)}
                                            <h1 style={{ marginLeft: '10px', textAlign: 'left', color: userFrame.darkMode ? '#D8D8D8' : ("#4B286D"), fontSize: 15, fontWeight: 'normal' }}>
                                                Save up to <span style={{ fontWeight: 'bold' }}>${getMaxPromoSavings(product)}</span> per month
                                            </h1>
                                        </div>

                                        <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', width: '90%', marginLeft: "10%", marginTop: '10px' }}>
                                            {renderProductIcon('Gift', false, 24)}
                                            <h1 style={{ marginLeft: '10px', textAlign: 'left', color: userFrame.darkMode ? '#D8D8D8' : ("#4B286D"), maxHeight: '70px', WebkitLineClamp: 3, display: '-webkit-box', WebkitBoxOrient: 'vertical', overflow: 'hidden' }}>
                                                <span style={{ fontWeight: 'bold' }}>{product.offers.length}</span> promotional offers available
                                            </h1>
                                        </div>

                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '50px', marginTop: '30px' }}>
                                            <button onClick={() => { selectPromotionalOffer(p, product); }} style={{ width: '250px', height: '50px', backgroundColor: '#2B8000', borderRadius: '5px', outline: 'none', border: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <img src={plus} style={{ width: '35px', }} />
                                                <h1 style={{ color: 'white', fontWeight: 'bold', marginLeft: '5px' }}>
                                                    Add to your bundle
                                                </h1>
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            )
                        })}
                </div>
            </div>
        )
    }

    function renderAllOffers() {

        // get all prods that are selected
        var productTypes = []
        var products = userFrame.products.filter((prod) => {
            if (prod.product == true) {
                productTypes.push(prod.type)
                return prod
            }
        })

        var numOfOffersToShow = 0;
        // get all commitments for those products
        var commitmentSelected = false
        products.forEach((prod) => {
            if (prod.commitment != '') {
                commitmentSelected = true
                numOfOffersToShow++;
            }
        })

        return userFrame.products.map((p) => {
            if (p.product == true && p.commitment != '') {
                {/* Products Offer Column */ }
                return (
                    <div key={(p.type)} style={{ height: !commitmentSelected ? 0 : ((height - (100)) / 2), width: numOfOffersToShow > 3 ? (width / 3.3) : (width / 3), display: 'inline-block', }}>
                        <div style={{ height: '60px', width: numOfOffersToShow > 3 ? (width / 3.3) : (width / 3), }}>
                            <h1 style={{ color: userFrame.darkMode ? '#D8D8D8' : "#4B286D", paddingLeft: '10%', height: '60px', width: numOfOffersToShow > 3 ? (width / 3.3) : (width / 3), display: 'flex', justifyContent: 'left', alignItems: 'end', fontWeight: 'normal', fontSize: '20px', textDecoration: 'none', height: '60px', }}>
                            {renderProductIcon(p.type, false, 32)} <span style={{marginLeft:'10px', marginRight:'5px'}}>{p.type}</span>Offers
                            </h1>
                        </div>

                        <div style={{ height: ((height - (100)) / 2) - 60, width: numOfOffersToShow > 3 ? (width / 3.3) : (width / 3), overflowY: 'scroll', }}>
                            {p.offers.map((offer, i) => {
                                return (
                                    <div
                                        key={offer.id}
                                        onClick={() => { selectRowOffer(offer.id, p) }}
                                        style={{
                                            height: '60px', width: (numOfOffersToShow > 3 ? (width / 3.3) : (width / 3)) - 40,
                                            borderRadius: '5px', marginBottom: '10px', marginTop: '10px',
                                            marginLeft: '20px', marginRight: '20px', boxShadow: userFrame.darkMode ? 'none' : '0px 0px 7px 1px rgba(0,0,0,0.3)',
                                            backgroundColor: (userFrame.darkMode ? (p.offer == offer.id ? '#2A2C2E' : '#71757B') : 'white'),
                                        }}>

                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '60px' }}>
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '30%', }}>
                                                {/* Selection */}
                                                <OfferCheckBox product={p} offer={offer} title={""} />
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '70%', }}>

                                                <h1 style={{ marginLeft: '10px', fontSize: '40px', fontWeight: 'bold', color: userFrame.darkMode ? '#D8D8D8' : "#4B286D" }}>
                                                    <span style={{ verticalAlign: 'super', fontSize: '20px', fontWeight: 'normal' }}>
                                                        $
                                                    </span>
                                                    {p.commitment == 'No term' ? offer.priceNoTerm : (p.promoActivated && offer.promoDiscount != 'none' ? offer.pricePromo : offer.price)}
                                                    <span style={{ fontSize: '20px', fontWeight: 'normal' }}>
                                                        /mo.
                                                    </span>
                                                </h1>
                                            </div>

                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', }}>
                                                <a onClick={() => showDetails(true, offer.id)} style={{ textAlign: 'center', textDecoration: 'underline', color: userFrame.darkMode ? '#D8D8D8' : "#4B286D", fontSize: 15, fontWeight: 'normal' }}>
                                                    View Details
                                                </a>
                                            </div>
                                        </div>


                                    </div>
                                )
                            })}
                            <div style={{ height: '90px', width: '100%' }} />
                        </div>
                    </div>
                )
            }
        })

    }

    function renderAllCommitments() {

        // get all prods that are selected
        var productTypes = []
        var products = userFrame.products.filter((prod) => {
            if (prod.product == true) {
                productTypes.push(prod.type)
                return prod
            }
        })

        var numOfCommitmentsToShow = products.length;
        // get all commitments for those products
        var commitmentSelected = false
        products.forEach((prod) => {
            if (prod.commitment != '') {
                commitmentSelected = true
            }
        })

        // get selected products commitments
        return userFrame.products.map((prod) => {
            if (prod.product == true) {
                return (
                    <div key={(prod.type)} style={{ height: commitmentSelected ? (height - (100)) / 2 : height - (100), width: numOfCommitmentsToShow > 2 ? (width / 3.3) : (width / 3), display: 'inline-block', overflowY: 'hidden', }}>
                        <div style={{ height: '60px', width: numOfCommitmentsToShow > 2 ? (width / 3.3) : (width / 3), }}>
                            <div style={{ color: userFrame.darkMode ? '#D8D8D8' : "#4B286D", paddingLeft:'10%', height: '60px', width: numOfCommitmentsToShow > 2 ? (width / 3.3) : (width / 3), display: 'flex', justifyContent: 'left', alignItems: 'end', fontWeight: 'normal', fontSize: '20px', textDecoration: 'none', height: '60px', whiteSpace:'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',}}>
                                {renderProductIcon(prod.type, false, 32)} <span style={{marginLeft:'10px', marginRight:'5px'}}>{prod.type}</span> Commitments
                            </div>
                        </div>

                        <div style={{ height: commitmentSelected ? ((height - (100)) / 2) - 62 : (height - (100)) - 62, width: numOfCommitmentsToShow > 2 ? (width / 3.3) : (width / 3), overflowY: 'scroll', }}>
                            {prod.commitments.map((commitment, i) => {
                                return (
                                    <div
                                        key={i}
                                        onClick={() => { selectRowCommitment(prod, commitment.term) }}
                                        style={{
                                            height: '60px', width: ((numOfCommitmentsToShow > 2 ? (width / 3.3) : (width / 3)) - 40),
                                            borderRadius: '5px', marginBottom: '10px', marginTop: '10px',
                                            marginLeft: '20px', marginRight: '20px', boxShadow: userFrame.darkMode ? 'none' : '0px 0px 7px 1px rgba(0,0,0,0.3)',
                                            backgroundColor: (userFrame.darkMode ? (prod.commitment == commitment.term ? '#2A2C2E' : '#71757B') : 'white'),
                                        }}>

                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '60px' }}>
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '30%', }}>
                                                {/* Selection */}
                                                <CommitmentCheckbox product={prod} commitment={commitment} title={""} />
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', width: '70%', }}>
                                                {renderProductIcon(commitment.term, false, 32)}
                                                <h1 style={{ marginLeft: '10px', fontSize: '23px', fontWeight: 'bold', color: userFrame.darkMode ? '#D8D8D8' : "#4B286D" }}>
                                                    {commitment.term == 'No term' ?
                                                        'No Term'
                                                        :
                                                        <>
                                                            {commitment.term}
                                                            <span style={{ fontSize: '20px', fontWeight: 'normal' }}>
                                                                &nbsp;mo.
                                                            </span>
                                                        </>}
                                                </h1>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )
            }
        })

    }

    function renderAllProducts() {

        var count = userFrame.products.length
        var even = count % 2 == 0

        // get all prods that are selected
        var productTypes = []
        var products = userFrame.products.filter((prod) => {
            if (prod.product == true) {
                productTypes.push(prod.type)
                return prod
            }
        })

        var productsSelected = productTypes.length > 0 ? true : false;

        // get all commitments for those products
        var commitmentSelected = false
        products.forEach((prod) => {
            if (prod.commitment != '') {
                commitmentSelected = true
            }
        })

        // if productsSelected
        if (true) {
            return (
                /* At least one product selected */
                <div style={{ height: commitmentSelected ? (height - (100)) / 2 : height - (100), width: width / 3, display: 'inline-block', overflowY: 'hidden', }}>
                    <div style={{ height: '60px', width: width / 3, }}>
                        <h1 style={{ color: userFrame.darkMode ? '#D8D8D8' : "#4B286D", paddingLeft: '10%', height: '60px', width: width / 3, display: 'flex', justifyContent: 'left', alignItems: 'end', fontWeight: 'normal', fontSize: '20px', textDecoration: 'none', height: '60px', }}>
                            Select Products
                        </h1>
                    </div>
                    <div style={{ height: commitmentSelected ? ((height - (100)) / 2) - 62 : (height - (100)) - 62, width: width / 3, overflowY: 'scroll', }}>
                        {userFrame.products.map((product, i) => {

                            const highlight = product.product;

                            return (

                                <div
                                    key={i}
                                    onClick={() => { selectRowProduct(product, false) }}
                                    style={{
                                        height: '60px', width: ((width / 3) - 40),
                                        borderRadius: '5px', marginBottom: '10px', marginTop: '10px',
                                        marginLeft: '20px', marginRight: '20px', boxShadow: userFrame.darkMode ? 'none' : '0px 0px 7px 1px rgba(0,0,0,0.3)',
                                        backgroundColor: (userFrame.darkMode ? (product.product ? '#2A2C2E' : '#71757B') : 'white'),
                                    }}>
                                    {/* Product Tile */}
                                    <div style={{ height: '60px', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '30%', }}>
                                            {/* Selection */}
                                            <ProductCheckBox product={product} title={""} />
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', width: '70%', }}>
                                            {/* ICON TYPE */}
                                            {renderProductIcon(product.type, false, 32)}
                                            <h1 style={{ marginLeft: '10px', fontSize: '23px', fontWeight: 'bold', color: userFrame.darkMode ? '#D8D8D8' : "#4B286D" }}>
                                                {product.type}
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            )
        } else {
            /* No products selected */
            return (
                <div style={{ height: commitmentSelected ? (height - (100)) / 2 : height - (100), width: width, display: 'inline-block', overflowY: 'hidden', }}>
                    <div style={{ height: '60px', width: width, }}>
                        <h1 style={{ color: userFrame.darkMode ? '#D8D8D8' : "#4B286D", paddingLeft: '10%', height: '60px', width: width, display: 'flex', justifyContent: 'left', alignItems: 'end', fontWeight: 'normal', fontSize: '23px', textDecoration: 'underline', height: '60px', }}>
                            Select Products
                        </h1>
                    </div>
                    <div style={{ height: commitmentSelected ? ((height - (100)) / 2) - 62 : (height - (100)) - 62, width: width, overflowY: 'scroll', }}>
                        {userFrame.products.map((product, i) => {

                            const highlight = product.product;
                            return (

                                <div
                                    key={i}
                                    onClick={() => { selectRowProduct(product, false) }}
                                    style={{
                                        height: '150px', width: ((width) - 80),
                                        borderRadius: '5px', marginBottom: '40px', marginTop: '40px',
                                        marginLeft: '40px', marginRight: '40px', boxShadow: userFrame.darkMode ? 'none' : '0px 0px 7px 1px rgba(0,0,0,0.3)',
                                        backgroundColor: (userFrame.darkMode ? (product.product ? '#2A2C2E' : '#71757B') : 'white'),
                                    }}>

                                    {/* EXPAND COLLAPSE ARROW */}
                                    <div style={{ height: '100%', width: '10%', display: 'inline-block', verticalAlign: 'top' }}>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
                                            <ProductCheckBox product={product} title={""}/>
                                        </div>
                                    </div>
                                    {/* SMALLER ROW */}
                                    <div style={{ height: '100%', width: '90%', display: 'inline-block', verticalAlign: 'top', }}>
                                        <div style={{ display: 'inline-block', width: '30%', height: '100%', verticalAlign: 'top' }}>
                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', paddingLeft: '20%' }}>
                                                {/* ICON PROD TYPE */}
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginBottom: '15px' }}>
                                                    {renderProductIcon(product.type, false, 48)}
                                                    <h1 style={{ marginLeft: '10px', fontSize: '40px', fontWeight: 'bold', color: userFrame.darkMode ? '#D8D8D8' : "#4B286D" }}>
                                                        {product.type}
                                                    </h1>
                                                </div>
                                                {/* MEMO */}
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', }}>
                                                    <h1 style={{ textAlign: 'center', color: userFrame.darkMode ? '#D8D8D8' : "#4B286D" }}>
                                                        {"Add " + product.type + " to my bundle"}
                                                    </h1>
                                                </div>
                                            </div>
                                        </div>

                                        {/* PROD DESCRIPTION */}
                                        <div style={{ display: 'inline-block', width: '70%', height: '100%', verticalAlign: 'top', }}>
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                                                <h1 style={{ color: userFrame.darkMode ? '#D8D8D8' : "#4B286D", marginLeft: '30px' }}>
                                                    {product.description}
                                                </h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            )
        }




    }

    function renderAllPCOs() {

        // get all prods that are selected
        var productTypes = []
        var products = userFrame.products.filter((prod) => {
            if (prod.product == true) {
                productTypes.push(prod.type)
                return prod
            }
        })
        var productsSelected = productTypes.length > 0 ? true : false;

        // get all commitments for those products
        var commitmentSelected = false
        products.forEach((prod) => {
            if (prod.commitment != '') {
                commitmentSelected = true
            }
        })

        const bodyHeight = height - 100;

        return (
            <div style={{ height: bodyHeight, width: width, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ height: commitmentSelected ? (bodyHeight) / 2 : bodyHeight, width: width, display: 'flex', justifyContent: 'start', alignItems: 'start', }}>
                    <div 
                        style={{ height: commitmentSelected ? (bodyHeight) / 2 : bodyHeight, width: productsSelected ? width / 3 : width, 
                        borderBottom: productsSelected && commitmentSelected ? (userFrame.darkMode ? '1px solid #D8D8D8' : '1px solid #4B286D') : 'none', 
                        borderRight: productsSelected ? (userFrame.darkMode ? '1px solid #D8D8D8' : '1px solid #4B286D') : 'none' }}>
                            {renderAllProducts()}
                    </div>
                    <div 
                        style={{ height: commitmentSelected ? bodyHeight / 2 : bodyHeight, width: productsSelected ? (width / 3) * 2 : 0, 
                        visibility: productsSelected ? 'visible' : 'hidden', overflowX: 'scroll', overflowY: 'hidden', whiteSpace: 'nowrap', 
                        borderBottom: commitmentSelected ? userFrame.darkMode ? '1px solid #D8D8D8' : '1px solid #4B286D' : 'none', }}>
                            {renderAllCommitments()}
                    </div>
                </div>
                <div 
                    style={{ height: commitmentSelected ? bodyHeight / 2 : 0, visibility: commitmentSelected ? 'visible' : 'hidden', 
                    width: width, display: 'flex', justifyContent: 'start', alignItems: 'center', overflowX: 'scroll', overflowY: 'hidden', 
                    whiteSpace: 'nowrap', }}>
                        {renderAllOffers()}
                </div>
            </div>
        )
    }

    function renderFlowSequence() {

        return (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <FlowIndicator title="Select Offers" page={0} />
                <FlowIndicator title="Customize Offers" page={1} />
                <FlowIndicator title="Review Order" page={2} />
                <FlowIndicator title="Payment" page={3} />
            </div>
        )
    }

    function renderProductIcon(type, selected, size) {
        switch (type) {
            case 'TV':
                return <Tv size={size} variant={userFrame.darkMode ? "inverted" : (selected ? "inverted" : "default")} />
            case 'Internet':
                return <Internet size={size} variant={userFrame.darkMode ? "inverted" : (selected ? "inverted" : "default")} />
            case 'SHS':
                return <SecurityHouse size={size} variant={userFrame.darkMode ? "inverted" : (selected ? "inverted" : "default")} />
            case 'Phone':
                return <Call size={size} variant={userFrame.darkMode ? "inverted" : (selected ? "inverted" : "default")} />
            case 'No term':
                return <LockOpened size={size} variant={userFrame.darkMode ? "inverted" : (selected ? "inverted" : "default")} />
            case '12':
                return <Calendar size={size} variant={userFrame.darkMode ? "inverted" : (selected ? "inverted" : "default")} />
            case '24':
                return <Calendar size={size} variant={userFrame.darkMode ? "inverted" : (selected ? "inverted" : "default")} />
            case '36':
                return <Calendar size={size} variant={userFrame.darkMode ? "inverted" : (selected ? "inverted" : "default")} />
            case '60':
                return <Calendar size={size} variant={userFrame.darkMode ? "inverted" : (selected ? "inverted" : "default")} />
            case 'dollar-sign':
                return <Deals size={size} variant={userFrame.darkMode ? "inverted" : (selected ? "inverted" : "default")} />
            case 'LivingWell':
                return <Heartbeat size={size} variant={userFrame.darkMode ? "inverted" : (selected ? "inverted" : "default")} />
            case 'Channels':
                return <Channels size={size} variant={userFrame.darkMode ? "inverted" : (selected ? "inverted" : "default")} />
            case 'Data':
                return <DataLimit size={size} variant={userFrame.darkMode ? "inverted" : (selected ? "inverted" : "default")} />
            case 'Camera':
                return <SecurityCamera size={size} variant={userFrame.darkMode ? "inverted" : (selected ? "inverted" : "default")} />
            case 'CallOut':
                return <CallOut size={size} variant={userFrame.darkMode ? "inverted" : (selected ? "inverted" : "default")} />
            case 'Information':
                return <Information size={size} variant={userFrame.darkMode ? "inverted" : (selected ? "inverted" : "default")} />
            case 'Gift':
                return <Gift size={size} variant={userFrame.darkMode ? "inverted" : (selected ? "inverted" : "default")} />
            case 'PriceTag':
                return <Deals size={size} variant={userFrame.darkMode ? "inverted" : (selected ? "inverted" : "default")} />
            case 'PiggyBank':
                return <PiggyBank size={size} variant={userFrame.darkMode ? "inverted" : (selected ? "inverted" : "default")} />
            case 'Watch':
                return <Watch size={size} variant={userFrame.darkMode ? "inverted" : (selected ? "inverted" : "default")} />
            case 'PikTV':
                return <PikTV size={size} variant={userFrame.darkMode ? "inverted" : (selected ? "inverted" : "default")} />
            case 'Calendar':
                return <Calendar size={size} variant={userFrame.darkMode ? "inverted" : (selected ? "inverted" : "default")} />
            case 'Customize':
                return <Preference size={size} variant={userFrame.darkMode ? "inverted" : (selected ? "inverted" : "default")} />
            case 'Review':
                return <Clipboard size={size} variant={userFrame.darkMode ? "inverted" : (selected ? "inverted" : "default")} />
            case 'Checkout':
                return <CreditCard size={size} variant={userFrame.darkMode ? "inverted" : (selected ? "inverted" : "default")} />
            default:
                return <Deals size={size} variant={userFrame.darkMode ? "inverted" : (selected ? "inverted" : "default")} />

        }
    }

    function renderMessage() {

        switch (userFrame.page) {
            case 0:
                return "Select Products"
            case 1:
                return "Select Commitment"
            case 2:
                return "Select Offers"
            case 3:
                return "Customize Offers"
            case 4:
                return "Review Order"
            case 5:
                return "Payment"

        }

    }

    function renderIcon() {

        switch (userFrame.page) {
            case 0:
                return "PikTV"
            case 1:
                return "Calendar"
            case 2:
                return "PriceTag"
            case 3:
                return "Customize"
            case 4:
                return "Review"
            case 5:
                return "Checkout"

        }

    }

    function renderPage() {

        switch (userFrame.page) {
            case 0:
                return renderAllPCOs()
            case 1:
                return renderAllCommitments()
            case 2:
                return renderAllOffers()
            case 3:
                return
            case 4:
                return
            case 5:
                return

        }

    }

    return (
        <div >
            {/* HEADER */}
            <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100px', backgroundColor: '#2A2C2E', zIndex: 17, boxShadow: userFrame.darkMode ? 'none' : '0 4px 2px -2px #9E9E9E' }}>
                {/* Exit Order Hub */}
                <div style={{ display: 'inline-block', width: '10%', height: '100%', }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
                        <Hamburger color={userFrame.darkMode ? '#D8D8D8' : '#D8D8D8'} toggle={exitHasBeenClicked} toggled={userFrame.showPCO} />
                    </div>
                </div>
                {/* Flow Sequence */}
                <div style={{ display: 'inline-block', height: '100%', width: '80%', verticalAlign: 'top' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%', }}>
                        {renderFlowSequence()}
                        {/*{renderProductIcon(renderIcon(), true, 32)}
                        <h1 style={{ color: userFrame.darkMode ? '#D8D8D8' : '#D8D8D8', fontSize: '27px', marginLeft: '10px' }}>
                            {renderMessage()}
                        </h1>*/}
                    </div>
                </div>
            </div>

            {/* Body */}
            <div style={{ position: 'absolute', top: 100, left: 0, height: height - (100), width: '100%', backgroundColor: userFrame.darkMode ? '#54595F' : 'white', zIndex: 16, overflow: 'scroll', }}>
                {/* Flow Sequence 
                <div style={{ height: '20%', width: '100%', backgroundColor: userFrame.darkMode ? '#54595F' : 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {renderFlowSequence()}
                </div>*/}
                {/* Products */}
                <div style={{ width: '100%', height: height - 100, display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly', }}>
                    {renderPage()}
                    {/*<div style={{ height: '70px', width: '100%' }} />*/}
                </div>
            </div>

            {/* VIEW CART DETAILS */}
            {numberOfProductsSelected() > 0 &&
                <div style={{ position: 'absolute', bottom: 0, left: 0, width: '100%', backgroundColor: '#2A2C2E', zIndex: 17, boxShadow: userFrame.darkMode ? 'none' : '0px 0px 7px 1px rgba(0,0,0,0.3)' }}>
                    <div style={{ display: 'inline-block', width: '70%', height: '80px', verticalAlign: 'top' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                            <h1 style={{ color: '#D8D8D8', fontWeight: 'bold', fontSize: '17px', marginLeft: '15px' }}>1-844-372-8559 toll free</h1>
                            <h1 style={{ color: '#D8D8D8', fontWeight: 'bold', fontSize: '17px', marginLeft: '15px' }}>|</h1>
                            <a onClick={() => { viewCartDetails() }} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '15px' }}>
                                <h1 style={{ color: '#D8D8D8', fontWeight: 'bold', fontSize: '17px', marginRight: '5px' }}>View cart details</h1>
                                <img src={!userFrame.viewCartDetails ? arrowUpW : arrowDownW} width={'20px'} />
                            </a>
                            <h1 style={{ color: '#D8D8D8', fontWeight: 'bold', fontSize: '17px', marginLeft: '15px' }}>|</h1>
                            <h1 style={{ color: '#D8D8D8', fontWeight: 'bold', fontSize: '17px', marginLeft: '15px' }}>Due monthly before tax</h1>
                            <h1 style={{ color: '#D8D8D8', fontWeight: 'bold', fontSize: '27px', marginLeft: '15px' }}>${calculateTotal()}.<span style={{ fontSize: '17px', verticalAlign: 'super' }}>00</span></h1>
                        </div>
                    </div>
                    <div style={{ display: 'inline-block', width: '30%', height: '80px', verticalAlign: 'top' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                            <button onClick={() => {/* onChangeFlowPage(userFrame.page + 1) */}} style={{ width: '200px', height: '50px', backgroundColor: pageIsUnlocked(userFrame.page + 1) ? (userFrame.darkMode ? '#71757B' : '#2B8000') : '#71757B', color: userFrame.darkMode ? '#D8D8D8' : 'white', fontWeight: 'bold', borderRadius: '5px', outline: 'none', border: 'none' }}>
                                Next
                            </button>
                        </div>
                    </div>

                    {/* LIGHT GREY BOX */}
                    {userFrame.viewCartDetails &&
                        <div style={{ width: '90%', marginLeft: '5%', paddingTop: '20px', paddingBottom: '20px', borderRadius: '5px', backgroundColor: '#54595F', marginBottom: '20px' }}>
                            {calculateTotal() <= 0 ?
                                <h1 style={{ color: 'white', fontSize: '17px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Attention size={24} variant={"inverted"} />
                                    <span style={{ marginLeft: '10px' }}>You have not selected any offers yet</span>
                                </h1>
                                :
                                <>
                                    {/* HEADER */}
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', }}>

                                        <h1 style={{ color: 'white', fontSize: '23px', fontWeight: 'bold', marginLeft: '5%', width: '10%', }}>

                                        </h1>
                                        <h1 style={{ color: 'white', fontSize: '23px', fontWeight: 'bold', marginLeft: '5%', width: '20%', }}>
                                            <Gift size={24} variant={"inverted"} />
                                            <span style={{ marginLeft: '10px' }}>Product</span>
                                        </h1>

                                        <h1 style={{ color: 'white', fontSize: '23px', fontWeight: 'bold', marginLeft: '5%', width: '20%', }}>
                                            <Deals size={24} variant={"inverted"} />
                                            <span style={{ marginLeft: '10px' }}>Price</span>
                                        </h1>

                                        <h1 style={{ color: 'white', fontSize: '23px', fontWeight: 'bold', marginLeft: '5%', width: '20%', }}>
                                            <Calendar size={24} variant={"inverted"} />
                                            <span style={{ marginLeft: '10px' }}>Term</span>
                                        </h1>


                                    </div>

                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '90%', paddingLeft: '5%' }}>
                                        <HairlineDivider gradient />
                                    </div>
                                    <div style={{ marginBottom: '20px' }} />

                                    {/* SELECTIONS */}
                                    {houses[userFrame.houseId - 1].offer.map((o, i) => {
                                        return (

                                            <div key={i} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: '15px' }}>

                                                <h1 style={{ color: 'white', fontSize: '23px', marginLeft: '5%', width: '10%', }}>
                                                    <a onClick={() => showDetails(true, o.id)} style={{ textAlign: 'center', color: 'white', fontSize: '15px', marginRight: '10px', textDecoration: 'underline' }}>
                                                        View Details
                                                    </a>
                                                </h1>

                                                <h1 style={{ color: 'white', fontSize: '23px', marginLeft: '5%', width: '20%', }}>
                                                    {renderProductIcon(o.product, true, 24)}
                                                    <span style={{ marginLeft: '10px' }}>{o.product}</span>
                                                </h1>

                                                <h1 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: 'white', fontSize: '23px', marginLeft: '5%', width: '20%', }}>
                                                    <span>{'$ '}{getCostForOffer(o.id)}.<span style={{ fontSize: '17px', }}>00 / mo.</span></span>
                                                </h1>


                                                <div style={{ color: 'white', fontSize: '23px', marginLeft: '5%', width: '20%', display:'flex', justifyContent:'space-between', alignItems:'center' }}>
                                                    <h1>{o.commitment}{o.commitment != 'No term' ? <span style={{ fontSize: '17px' }}>{' mo.'}</span> : ''}</h1>
                                                    <a onClick={() => removeOffer(o.id)} style={{ marginLeft: '20px', marginTop: '20px' }}>
                                                        <Subtract size={24} color={"white"} />
                                                    </a>
                                                </div>




                                            </div>

                                        )
                                    })}
                                </>}
                        </div>
                    }
                </div>}
        </div>
    );
}

export default ProductsCommitmentsOffers;

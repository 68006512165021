import React, { useState } from 'react';

const ToggleSwitch = ({ darkMode }) => {
  const [isToggled, setIsToggled] = useState(false);

  const getBackgroundColor = () => {
    if (isToggled) {
      return darkMode ? '#F7F7F8' : '#4B286D'; // Different color for dark mode when toggled
    }
    return darkMode ? '#555' : '#ccc'; // Darker background in dark mode when not toggled
  };

  const toggleStyles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: 'fit-content',
    },
    switch: {
      width: '40px',
      height: '20px',
      backgroundColor: getBackgroundColor(),
      borderRadius: '10px',
      position: 'relative',
      cursor: 'pointer',
      transition: 'background-color 0.2s',
    },
    knob: {
      width: '18px',
      height: '18px',
      backgroundColor: isToggled&&darkMode?'#54595F':'white',
      borderRadius: '50%',
      position: 'absolute',
      top: '1px',
      left: isToggled ? '20px' : '1px',
      transition: 'left 0.2s',
    },
    label: {
      marginTop: '3px',
      textAlign: 'center',
      color: isToggled ? (darkMode ? '#F7F7F8' : '#4B286D') : (darkMode ? '#ddd' : '#333'),
      fontSize: '14px',
      whiteSpace: 'nowrap',
    },
  };

  return (
    <div style={toggleStyles.container}>
      <div style={toggleStyles.switch} onClick={() => setIsToggled(!isToggled)}>
        <div style={toggleStyles.knob}></div>
      </div>
      <div style={toggleStyles.label}>Entered House</div>
    </div>
  );
};

export default ToggleSwitch;

import React, { useState, useEffect,} from 'react';

import ButtonHouse from '../SubComponents/ButtonHouseVariant';

import { useSelector, useDispatch } from 'react-redux';
import {updateHouse} from '../../../../redux/actions/houseActions'
import {updateUserFrame} from '../../../../redux/actions/userFrameActions'
import ToggleSwitch from '../SubComponents/Toggle';

function SmallerBottomBar({}) {

    const userFrame = useSelector(state=>state.user_frame_reducer[0])
    const houses = useSelector(state=>state.house_reducer)
    const dispatch = useDispatch()
    const width = window.innerWidth;

    function disablePremise() {
        if(houses[userFrame.houseId-1].icon == 'DNK'){
          return(
              <div style={{width:'100%', height:'100%'}}>                
                <div style={{position:'absolute', left:'0', top:'0', width:'100%', height:'100%', zIndex:6, backgroundColor:'rgba(125, 125, 125, 0.3)', boxShadow:`0 0 10px 10px rgba(125,125,125,0.7)`  }}>
                    {/*
                    <div style={{marginTop:'35%', width:'100%',marginLeft:'-5%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <IconHouse icon={houses[userFrame.houseId-1].icon}/>
                    </div>*/}
                    <div style={{marginTop:'15%', width:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <h1 style={{color:userFrame.darkMode?'white':'#C12335', fontSize:'30px', fontWeight:'bold', textAlign:'center', textShadow:'0px 0px 15px #FFF'}}>Do Not Knock</h1>
                    </div>
                </div>
              </div>
    
          )
        }
    }


  return (

        <div style={{height:'100%', top:0, left:0,width:'100%', overflowY:'hidden', }}>
           
        {/* Details Side Card - No drawer click*/}
        {disablePremise()}
        <div style={{position:'absolute', top: '0', height:'100%', width:'100%',backgroundColor:userFrame.darkMode?'#2A2C2E':'white', overflowY:'hidden', paddingTop:'40px'}}>
        
            <h1 style={{color:userFrame.darkMode?'white':'#4B286D', marginTop:window.innerWidth>600?'20px':'15px', marginLeft:'7%', fontWeight:'bold'}}>
                {houses[userFrame.houseId-1].address}
            </h1>

            <div style={{display:'block', flexGrow:1, width:'100%',backgroundColor:userFrame.darkMode?'#2A2C2E':'white', overflowY:'hidden', marginTop: width>600 ? 0 : '2%' }}>
            
                <div style={{ left:0, height:'100px', width:'90%', marginLeft:'5%', paddingTop:'2%', backgroundColor:userFrame.darkMode?'#2A2C2E':'white', overflowY:'hidden', display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                <div style={{width:'16.5%'}}>   
                        <div>
                        <ButtonHouse title = {"No One Home"} icon = {'NOH'} />
                        </div>
                    </div> 
                    <div style={{width:'16.5%'}}>   
                        <div>
                        <ButtonHouse title = {"Soft No"} extra={window.innerWidth>600?false:true} icon = {'SN'} />
                        </div>
                    </div>
                    <div style={{width:'16.5%'}}>   
                        <div>
                        <ButtonHouse title = {"Hard No"} extra={window.innerWidth>600?false:true} icon = {'HN'} />
                        </div>
                    </div> 
                    <div style={{width:'16.5%'}}>   
                        <div>
                        <ButtonHouse title = {"Wrong Party"} extra={window.innerWidth>600?false:true} icon = {'WP'}  />
                        </div>
                    </div>
                    <div style={{width:'16.5%'}}>   
                        <div>
                        <ButtonHouse title = {"Not Interested"} extra={window.innerWidth>600?false:true} icon = {'NI'} />
                        </div>
                    </div> 
                    <div style={{width:'16.5%'}}>   
                        <div>
                        <ButtonHouse title = {"Other Knocks"} extra={window.innerWidth>600?false:true} icon = {'OK'}  />
                        </div>
                    </div>
                    
                </div>
            </div>

            <div style={{display: 'block', width:'100%', display:'flex', justifyContent:'center', marginTop:width>600?0:'20px' }}>
                <ToggleSwitch darkMode={userFrame.darkMode} />
            </div>
        </div>
    </div>

        
    );
}

export default SmallerBottomBar;


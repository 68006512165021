import { LockClosed, LockOpened } from "@tds/core-decorative-icon";
import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from 'react-redux';
import { updateHouse } from '../../../redux/actions/houseActions'
import { updateUserFrame } from '../../../redux/actions/userFrameActions'

function FlowIndicator({ title, page }) {

    const userFrame = useSelector(state => state.user_frame_reducer[0])
    const houses = useSelector(state => state.house_reducer)
    const dispatch = useDispatch()

    function checkProducts() {
        
        var productSelected = false;
        userFrame.products.forEach((prod) => {
            if (prod.product == true) {
                productSelected = true;
            }
        })
        return productSelected;
    }

    function checkCommitment() {
        
        var commitmentSelected = false;
        var productSelected = null;
        userFrame.products.forEach((prod) => {
            if (prod.product == true) {
                productSelected = prod;
            }
        })

        if (productSelected != null && productSelected.commitment != '') {
            commitmentSelected = true;
        }
     
        return commitmentSelected;

    }

    function checkOffers() {
        
        return false

    }

    function onChangeFlowPage(page) {
        const u = userFrame;
        var orderFlowPages = u.orderFlowPages;
        var progressionStopped = false;

        var canProceed = false;
        
        switch (page) {
            case 0:
                //canProceed = true
                break;
            case 1:
                //canProceed = checkProducts()
                // reset
                break;
            case 2:
                //canProceed = checkCommitment()
                // reset selections on bundle
                break;
            case 3:
                //canProceed = checkOffers()
                break;
            case 4:

                break;
            case 5:

                break;

        }

        if(canProceed){
            for (var i = 0; i < orderFlowPages.length; i++) {
                // change first set of values to green, up to page clicked
                if (progressionStopped == false) {
                    orderFlowPages[i] = 1;
                } else {
                    orderFlowPages[i] = 0;
                }
                // change the rest to white
                if (i == page) {
                    progressionStopped = true;
                }
            }
            u.page = page
            u.orderFlowPages = orderFlowPages;
            dispatch(updateUserFrame(u))
        }
        
    }

    const width = '30px';
    const height = '30px';

    return (
        <div style={{height: '70px', width: '100%', marginLeft: page == 0 ? 0: '-40px',  display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', }}>
            
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', overflowX:'visible' }}>
                <div style={{ zIndex:0, width: '37%', height:'4px', backgroundColor: (page == 0 ? "#2A2C2E" :"white")}}/>
                
                <button
                    style={{ zIndex:1,
                        height: height, width: width, overflow: 'hidden',
                        cursor: 'pointer', display: 'inline-block', fontSize: '17px',
                        borderRadius: '100%', borderWidth: '1px', borderStyle: 'solid',
                        borderColor: (userFrame.darkMode ? '#D8D8D8' : 'white'),
                        backgroundColor:
                            userFrame.orderFlowPages[page] == 0
                                ?
                                ('#2A2C2E')
                                :
                                ('white'),

                    }}
                    onClick={() => onChangeFlowPage(page)}
                >
                    <h1 style={{
                        color: userFrame.orderFlowPages[page] == 0 ? ('white') : ('#2A2C2E'),
                        display: 'flex', alignItems: 'center', justifyContent: 'center',
                    }}>
                        {page + 1}
                    </h1>
                </button>

                <div style={{ zIndex:0,width: '37%', height:'4px', backgroundColor: (page == 3 ? "#2A2C2E" :"white")}}/>
            </div>

            <h1 style={{
                color: (userFrame.darkMode ? '#D8D8D8' : 'white'),
                display: 'flex', flexDirection:'row' ,alignItems: 'center', justifyContent: 'center',
                marginTop: '8px', fontSize: '14px', fontWeight: userFrame.orderFlowPages[page] == 1 && userFrame.orderFlowPages[page + 1] == 0 ? 'bold' : 'normal',
            }}>
                {title}
            </h1>
        </div>
    );
}

export default FlowIndicator;


import React, { useEffect, useState, useRef } from "react";

import checkMark from '../../../icons/checkmark/black.png';
import checkMarkWhite from '../../../icons/checkmark/white.png';

import { useSelector, useDispatch } from 'react-redux';
import { updateHouse } from '../../../redux/actions/houseActions'
import { updateUserFrame } from '../../../redux/actions/userFrameActions'

function CommitmentCheckBox({ product,commitment, title }) {

    const userFrame = useSelector(state => state.user_frame_reducer[0])
    const houses = useSelector(state => state.house_reducer)
    const dispatch = useDispatch()

    function showComponent() {

        return (
    
            <div style={{ width: '50px', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div
                    style={{
                        cursor: 'pointer', overflow: 'visible', backgroundColor: product.commitment == commitment.term  ? (userFrame.darkMode ? '#D8D8D8' : '#2B8000') : (userFrame.darkMode ? '#54595F' : 'white'),
                        boxShadow: product.commitment == commitment.term ? '0px 0px 10px -3px #000000':'none', width: '23px', height: '23px', borderRadius: '4px',
                        border: (userFrame.darkMode ? '1px solid white' : '1px solid rgba(0,0,0,0.55)')
                    }}>

                    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {product.commitment == commitment.term  ? <img src={userFrame.darkMode ? checkMark : checkMarkWhite} width={'17px'} style={{ overflow: 'visible' }} /> : <></>}
                    </div>
                </div>
            </div>
        )
    }


    return (showComponent());

};

export default CommitmentCheckBox;
import React, { useEffect, useState, } from "react";
import { useSelector, useDispatch } from "react-redux";

import MapContainer from './Components/MapContainer';
import RightSideBar from './Components/RightSideBar';
import SmallerRightSideBar from "./Components/SmallerRightSideBar";
import ButtonDrawerCircle from "./SubComponents/ButtonDrawerCircle";

import location from "../../../icons/current-location/location3.png"
import locationWhite from "../../../icons/current-location/location-white.png"
import legend from "../../../icons/legend/legend.png"
import legendCramped from "../../../icons/legend/legend-cramped.png"
import legendInfo from "../../../icons/info/info.png"

import {updateHouse} from '../../../redux/actions/houseActions'
import {updateUserFrame} from '../../../redux/actions/userFrameActions'

function MapViewTablet({}) {

  const userFrame = useSelector(state=>state.user_frame_reducer[0])
  const houses = useSelector(state=>state.house_reducer)
  const dispatch = useDispatch()
  
  function locationClicked(){
   
  // if button is not clicked, click it, set the location  
  if(!userFrame.locationButtonClicked){
    // check if we already have the location (fast)
    if(userFrame.locationLat != null){
      const u = userFrame
      u.zoomMap = 17;
      u.locationButtonClicked = true
      u.houseId = 0;
      u.drawer = 0
      u.defaultMapLat = u.locationLat
      u.defaultMapLng = u.locationLng
      dispatch(updateUserFrame(u))
    }else{
      // get the location
      navigator.geolocation.getCurrentPosition(({coords: {latitude: lat, longitude: lng}}) => {
        const u = userFrame
        u.zoomMap = 17;
        u.locationButtonClicked = true
        u.defaultMapLat = lat
        u.defaultMapLng = lng
        u.locationLat = lat
        u.locationLng = lng
        u.houseId = 0;
        u.drawer = 0
        dispatch(updateUserFrame(u))
      })
    }
    
  }else{
      // reset variables back to route
      const u = userFrame
      u.locationButtonClicked = false
      u.defaultMapLat = 49.23;
      u.defaultMapLng = -123.16;
      u.zoomMap = 18;
      dispatch(updateUserFrame(u))
  }
  
  }

  function toggleLegend() {
      const u = userFrame
      u.showLegend = !u.showLegend
      dispatch(updateUserFrame(u))  
  }

  function showLegend() {
    if(!userFrame.darkMode && !userFrame.streetView){
      return(
      <a onClick={()=>toggleLegend()}>
        {userFrame.showLegend?
      
        (userFrame.drawer==1?
            <img src={legendCramped} width={"250px"} style={{position: 'absolute', bottom:'20px', left:'10px', zIndex:3, boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)', }}/>
        :
            <img src={legend} width={"500px"} style={{position: 'absolute', bottom:'20px', left:'10px', zIndex:3, boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)', }}/>
        )
        : 
          <div style={{position: 'absolute', bottom:'20px', left:'10px', zIndex:3, boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)', width:'45px', height:'45px', backgroundColor:'white' }}>
              <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:'100%', width:'100%'}}>
                <img src={legendInfo} width={"30px"} style={{opacity:'0.5'}} />
              </div>
          </div>
        }
      </a>)
    }
  }

  function showLocationButton() {
    return (
      <button 
      onClick={() => {locationClicked()}}
      style={{position: 'absolute', top:'9px', right:'9px', height:'42px', width:'42px', borderStyle: 'none', 
      backgroundColor:userFrame.locationButtonClicked?(userFrame.darkMode?'#71757B':'#4B286D'):(userFrame.darkMode?'#D8D8D8':'white'), borderRadius:'3px', boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)', 
      cursor:'pointer', zIndex:6, display:'flex', justifyContent:'center', alignItems:'center',}}>
        <img src={userFrame.locationButtonClicked?locationWhite:location} width="25px" />
      </button>
    )
  }

  function showRightMapView() {
    return(
      <>               
          <div style={{display: 'inline-block',width: userFrame.houseId==0? '100%' : (userFrame.drawer==1?'30%':'85%'), height:'90%', top:'10%',left: 0, position: 'absolute',zIndex:9, overflow:'hidden' }}>    
              
              {showLocationButton()}
              
              {showLegend()}
              
              <MapContainer /> 
              
          </div>

          {/* If premise unselected on map, remove sidebar*/}
          {userFrame.houseId == 0 || userFrame.menu ? <></> :
            (userFrame.drawer == 2?
                <>
                {/*<div style={{display: 'inline-block', width:'15%', height:'90%', top: '10%', right:'0', position: 'absolute', backgroundColor:'white', boxShadow:'0 4px 2px 2px #9E9E9E'}}>
                </div>*/}

                <div style={{display: 'inline-block',width:'15%',height:'90%', top: '10%', right:'0', position: 'absolute', backgroundColor:userFrame.darkMode?'#2A2C2E':'white',boxShadow:userFrame.darkMode?'none':'0 4px 2px 2px #9E9E9E',zIndex:11}}>
                  <SmallerRightSideBar  />
                </div>

                <div style={{position:'absolute', top: '50%', right:0, width:'15%', transform:'translateY(-27.5px)', zIndex:12}}>
                  <ButtonDrawerCircle />
                </div>
                </>
            :
                <>  
                <div style={{display: 'inline-block',width: '70%', height:'90%', top: '10%', right:'0', position: 'absolute', backgroundColor:userFrame.darkMode?'#2A2C2E':'white', boxShadow:userFrame.darkMode?'none':'0 4px 2px 2px #9E9E9E',zIndex:10}}>
                </div>

                <div style={{display: 'inline-block',width: '70%', height:'100%', top:0, right:'0', position: 'absolute', backgroundColor:userFrame.darkMode?'#2A2C2E':'white',zIndex:11}}>
                  <RightSideBar/>
                </div>

                <div style={{position:'absolute', top: '50%', right:0, width:'70%', transform:'translateY(-27.5px)', zIndex:12}}>
                  <ButtonDrawerCircle />
                </div>
                </>
              )
          }
      </>

    )
  }

  function showLeftMapView() {
    return(
      <>               
          <div style={{display: 'inline-block',width: userFrame.houseId==0? '100%' : (userFrame.drawer==1?'30%':'85%'), height:'90%', top:'10%',right: 0, position: 'absolute',zIndex:9, }}>    
              
              {showLocationButton()}
              
              {showLegend()}
              
              <MapContainer /> 

          </div>

          {/* If premise unselected on map, remove sidebar*/}
          {userFrame.houseId == 0 || userFrame.menu ? <></> :
            (userFrame.drawer == 2?
                <>
                {/*<div style={{display: 'inline-block', width:'15%', height:'90%', top: '10%', left:'0', position: 'absolute', backgroundColor:'white', }}>
                </div>*/}

                <div style={{width:'15%',height:'90%', top: '10%', left:'0', position: 'absolute', backgroundColor:'white',boxShadow:userFrame.darkMode?'none':'0 4px 2px 2px #9E9E9E',zIndex:11}}>
                  <SmallerRightSideBar  />
                </div>

                <div style={{position:'absolute', top: '50%', left:'15%', transform:'translateY(-27.5px)', zIndex:12}}>
                  <ButtonDrawerCircle />
                </div>
                </>
            :
                <>  
                <div style={{width: '70%', height:'90%', top: '10%', left:'0', position: 'absolute', backgroundColor:userFrame.darkMode?'#2A2C2E':'white', boxShadow:userFrame.darkMode?'none':'0 2px 2px 2px #9E9E9E',zIndex:10}}>
                </div>

                <div style={{width: '70%', height:'100%', top:0, left:'0', position: 'absolute', backgroundColor:userFrame.darkMode?'#2A2C2E':'white',zIndex:11}}>
                  <RightSideBar/>
                </div>

                <div style={{position:'absolute', top: '50%', left:'70%', transform:'translateY(-27.5px)', zIndex:12}}>
                  <ButtonDrawerCircle />
                </div>
                </>
              )
          }
      </>

    )
  }



  return (
    <div >
      {userFrame.leftHandedDrawer ? showLeftMapView() : showRightMapView()}
    </div>
  );
}

export default MapViewTablet;


import React, { useState } from "react";

import { useSelector,useDispatch } from "react-redux";

import MapContainer from './Components/MapContainer';
import location from "../../../icons/current-location/location1.png"
import locationWhite from "../../../icons/current-location/location-white.png"
import legend from "../../../icons/legend/legend-cramped.png"
import legendInfo from "../../../icons/info/info.png"

import SmallerBottomBar from "./Components/SmallerBottomBar"
import BottomBar from './Components/BottomBar';
import ButtonDrawerCircle from './SubComponents/ButtonDrawerCircle'

import {updateHouse} from '../../../redux/actions/houseActions'
import {updateUserFrame} from '../../../redux/actions/userFrameActions'
import ToggleSwitch from "./SubComponents/Toggle";

function MapViewPhone({ }) {
  
  const userFrame = useSelector(state=>state.user_frame_reducer[0])
  const houses = useSelector(state=>state.house_reducer)
  const dispatch = useDispatch()
  const width = window.innerWidth;
  const [locationButton,setLocationButton] = useState(false)

  /*
  Location Button
  */
  function locationClicked(){
   
    // if button is not clicked, click it, set the location  
    if(!userFrame.locationButtonClicked){
      // check if we already have the location (fast)
      if(userFrame.locationLat != null){
        const u = userFrame
        u.zoomMap = 17;
        u.locationButtonClicked = true
        u.houseId = 0;
        u.drawer = 0
        u.defaultMapLat = u.locationLat
        u.defaultMapLng = u.locationLng
        dispatch(updateUserFrame(u))
      }else{
        // get the location
        navigator.geolocation.getCurrentPosition(({coords: {latitude: lat, longitude: lng}}) => {
          const u = userFrame
          u.zoomMap = 17;
          u.locationButtonClicked = true
          u.defaultMapLat = lat
          u.defaultMapLng = lng
          u.locationLat = lat
          u.locationLng = lng
          u.houseId = 0;
          u.drawer = 0
          dispatch(updateUserFrame(u))
        })
      }
      
    }else{
        // reset variables back to route
        const u = userFrame
        u.locationButtonClicked = false
        u.defaultMapLat = 49.23;
        u.defaultMapLng = -123.16;
        u.zoomMap = 18;
        dispatch(updateUserFrame(u))
    }
    
    }

  function showLocationButton() {
    return (
      <button 
      onClick={() => {locationClicked()}}
      style={{position: 'absolute', top:'9px', right:'9px', height:'42px', width:'42px', borderStyle: 'none', 
      backgroundColor:userFrame.locationButtonClicked?(userFrame.darkMode?'#71757B':'#4B286D'):(userFrame.darkMode?'#D8D8D8':'white'), borderRadius:'3px', boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)', 
      cursor:'pointer', zIndex:6, display:'flex', justifyContent:'center', alignItems:'center',}}>
        <img src={userFrame.locationButtonClicked?locationWhite:location} width="25px" />
      </button>
    )
  }

  /*
  Legend Button
  */
  function toggleLegend() {
    const u = userFrame
    u.showLegend = !u.showLegend
    dispatch(updateUserFrame(u))  
  }

  function showLegend(){
    if(!userFrame.darkMode && !userFrame.streetView){
      return(
      
        <a onClick={()=>toggleLegend()}>
          {userFrame.showLegend?

          (userFrame.drawer==0?
          <img src={legend} width="250px" style={{position: 'absolute', bottom:'20px', left:'10px', zIndex:4, boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)', }}/>
          :
          <></>)
          : 
            <div style={{position: 'absolute', bottom:'20px', left:'10px', zIndex:3, boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)', width:'45px', height:'45px', backgroundColor:'white' }}>
                <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:'100%', width:'100%'}}>
                  <img src={legendInfo} width={"30px"} style={{opacity:'0.5'}} />
                </div>
            </div>
          }
        </a>
      )
    }
  }


  function showMap(){
    return(
      <>
            <div style={{ display: 'inline-block',width: '100%', height: userFrame.houseId==0?'90%':(userFrame.drawer==2?(width>650?"65%":"50%"):'10%'), top: '10%', left: '0', position: 'absolute', overflow:'hidden'}}>
              
              {/* LOCATION BUTTON */}
              {showLocationButton()}
              
              {/* LEGEND */}
              {showLegend()}

              {/* MAP */}
              <MapContainer locationHasBeenClicked={locationButton} /> 
            </div>        
            
            {/* BOTTOM BAR (Preview and large) */}
            {userFrame.houseId != 0 &&
              <>
                <div style={{position:'absolute', top:userFrame.drawer == 2?(width>650?"75%":"60%"):"20%", left:'50%', transform:'translateX(-27.5px)', width:'50%', zIndex:7}}>
                  <ButtonDrawerCircle />
                </div>

                <div style={{display: 'inline-block',width: '100%', top:userFrame.drawer == 2?(width>650?"75%":"60%"):"20%", bottom: '0', left:'0', position: 'absolute', backgroundColor:userFrame.darkMode?'#54595F':'white', overflowY:'scroll',boxShadow:userFrame.darkMode?'none':'0px -4px 10px rgb(0 0 0 / 0.2)' }}>
                </div>

                <div style={{display: 'inline-block',width: '100%', top:userFrame.drawer == 2?(width>650?"75%":"60%"):"20%", bottom: '0', left:'0', position: 'absolute', backgroundColor:userFrame.darkMode?'#54595F':'white', overflowY:'scroll', }}>
                {userFrame.drawer == 2? <SmallerBottomBar /> : <BottomBar/>}
                </div>
              </>}
          </>
    )
  }
  
  return (

    <div >
      {showMap()}
    </div>
    
  );
}

export default MapViewPhone;


import React, { Component, useEffect, useState, useCallback  } from 'react';
import { GoogleMap, useJsApiLoader, OverlayView, DirectionsRenderer } from '@react-google-maps/api';

import Marker from '../SubComponents/Marker';
import StickMan from '../SubComponents/StickMan';
import LocationMarker from '../SubComponents/LocationMarker';
import darkMap from './DarkMap'

import { useSelector, useDispatch } from 'react-redux';
import {updateHouse} from '../../../../redux/actions/houseActions'
import {updateUserFrame} from '../../../../redux/actions/userFrameActions'

function MapContainer({locationHasBeenClicked}) {

  const userFrame = useSelector(state=>state.user_frame_reducer[0])
  const houses = useSelector(state=>state.house_reducer)
  const dispatch = useDispatch()
  const width = window.innerWidth;

  const [defaultLat,setDefaultLat] = useState(49.23)
  const [defaultLng,setDefaultLng] = useState(-123.16)
  const [currentLong, setCurrentLong] = useState(null)
  const [currentLat, setCurrentLat] = useState(null)

  const [zoom, setZoom] = useState(18)

  const [reload,setReload] = useState(0)

  const [map, setMap] = useState(null)

  const [directionsResponse,setDirectionsResponse] = useState(null)
  const [distance,setDistance] = useState('')
  const [duration,setDuration] = useState('')

  const onLoad = useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    setMap(map)
  }, [])

  const onUnmount = useCallback(function callback(map) {
    setMap(null)
  }, [])

  useEffect(() => {
    initializePremiseMarkers()
    calculateRoute()
  }, []);
  
  /*
  Load current location, live feed
  */
  useEffect(()=>{

    if(userFrame.locationButtonClicked){
      let isMounted = true

      // load current location
      navigator.geolocation.getCurrentPosition(({coords: {latitude: lat, longitude: lng}}) => {
        if(isMounted){
          const pos = {lat, lng}
          const u = userFrame
          u.defaultMapLat = pos.lat
          u.defaultMapLng = pos.lng
          u.locationLat = pos.lat
          u.locationLng = pos.lng
          dispatch(updateUserFrame(u))
        }
      })
      return () => { isMounted = false }
    }
  })

function initializePremiseMarkers(){
    
  // dummy data - pre api
  const markers = [{lat:49.23041, lng:-123.1599, address:'123 Address Road, Vancouver, BC (SFU)', notes:'Most tenants interested', existingCustomer:true, products: ['HS150','no','no','HP','no','TOS','no'], copperCurrent:true, fibreCurrent:false,copperAvail:true, fibreAvail:true, ruralTech:false, icon:'multi', knocks:[{value:'No One Home', date:'1/09/2021', time:'4:18 PM'},{value:'Come Back', date:'1/3/2022', time:'11:23 AM'},{value:'Covid', date:'1/23/2022', time:'1:17 PM'}],details:[{key: "Telus Rewards",value:"Yes"}, {key: "My Care",value:"No"}, {key: "L&R Promos",value:"Yes"}, {key: "Cancelled Previous Services",value:"No"},{key:'Existing Products', value:'Home Phone, Online Security'},{key:'Available Technology', value:'Fibre, Copper'},{key:'COID', value:'AB87H90'},{key:'FSA', value:'4081A'},{key:'Drop Permission Collected', value:'Yes'},{key:'Drop Constructed', value:'Yes'},{key:'Sell Decision', value:'Book Order'}, ], dropRequired:false, selfInstall:false, westernPremise:true, dueDate:"March 27th"},
  {lat:49.23028, lng:-123.1599,address:'124 Address Road, Vancouver, BC (SXU)', notes:'Drop required', existingCustomer:true, products: ['HS150','Pik','no','no','TELUS','no','LWC',], copperCurrent:true, fibreCurrent:false,copperAvail:true, fibreAvail:true, ruralTech:false, icon:'SN', knocks:[{value:'No One Home', date:'1/09/2021', time:'4:18 PM'},{value:'Come Back', date:'1/3/2022', time:'11:23 AM'},{value:'Covid', date:'1/23/2022', time:'1:17 PM'}],details:[{key: "Telus Rewards",value:"Yes"}, {key: "My Care",value:"No"}, {key: "L&R Promos",value:"Yes"}, {key: "Cancelled Previous Services",value:"No"},{key:'Existing Products', value:'Internet 150, ADT Security, Optik Tv'},{key:'Available Technology', value:'Copper'},{key:'COID', value:'AB87H90'},{key:'FSA', value:'4081A'},{key:'Drop Permission Collected', value:'Yes'},{key:'Drop Constructed', value:'Yes'},{key:'Sell Decision', value:'Book Order'}, ], dropRequired:false,selfInstall:true,westernPremise:true,westernPremise:true, dueDate:"March 29th"},
  {lat:49.23015, lng:-123.1599,address:'125 Address Road, Vancouver, BC (MDU)', notes:'No notes', existingCustomer:true, products: ['unavail','unavail','no','unavail','KOODO','no','no',], copperCurrent:true, fibreCurrent:false,copperAvail:true, fibreAvail:true, ruralTech:false, icon:'FU', knocks:[{value:'No One Home', date:'1/09/2021', time:'4:18 PM'},{value:'Come Back', date:'1/3/2022', time:'11:23 AM'},{value:'Covid', date:'1/23/2022', time:'1:17 PM'}],details:[{key: "Telus Rewards",value:"Yes"}, {key: "My Care",value:"No"}, {key: "L&R Promos",value:"Yes"}, {key: "Cancelled Previous Services",value:"No"},{key:'Existing Products', value:'Mobility, Home Phone, Online Security, ADT Security'},{key:'Available Technology', value:'Fibre, Copper'},{key:'COID', value:'AB87H90'},{key:'FSA', value:'4081A'},{key:'Drop Permission Collected', value:'Yes'},{key:'Drop Constructed', value:'Yes'},{key:'Sell Decision', value:'Book Order'}, ], dropRequired:true,selfInstall:false,westernPremise:false, dueDate:"May 4th"},
  {lat:49.23002, lng:-123.1599,address:'126 Address Road, Vancouver, BC (SFU)', notes:'Not interested', existingCustomer:true, products: ['unavail','unavail','ADT','unavail','Public','no','LWC',], copperCurrent:true, fibreCurrent:false,copperAvail:true, fibreAvail:false, ruralTech:false, icon:'NI', knocks:[{value:'No One Home', date:'1/09/2021', time:'4:18 PM'},{value:'Come Back', date:'1/3/2022', time:'11:23 AM'},{value:'Covid', date:'1/23/2022', time:'1:17 PM'}],details:[{key: "Telus Rewards",value:"Yes"}, {key: "My Care",value:"No"}, {key: "L&R Promos",value:"Yes"}, {key: "Cancelled Previous Services",value:"No"},{key:'Existing Products', value:'Mobility, Home Phone, Online Security, Optik Tv'},{key:'Available Technology', value:'Copper'},{key:'COID', value:'AB87H90'},{key:'FSA', value:'4081A'},{key:'Drop Permission Collected', value:'Yes'},{key:'Drop Constructed', value:'Yes'},{key:'Sell Decision', value:'Book Order'}, ], dropRequired:false,selfInstall:false,westernPremise:false, dueDate:"May 2nd"},
  {lat:49.2299, lng:-123.1599,address:'131 Address Road, Vancouver, BC (SFU)', notes:'Follow up Feb 17th', existingCustomer:true, products: ['HS1GB','Optik','TELUS','no','TELUS','no','no',], copperCurrent:false, fibreCurrent:true,copperAvail:false, fibreAvail:true, ruralTech:false, icon:'HN', knocks:[{value:'No One Home', date:'1/09/2021', time:'4:18 PM'},{value:'Come Back', date:'1/3/2022', time:'11:23 AM'},{value:'Covid', date:'1/23/2022', time:'1:17 PM'}],details:[{key: "Telus Rewards",value:"Yes"}, {key: "My Care",value:"No"}, {key: "L&R Promos",value:"Yes"}, {key: "Cancelled Previous Services",value:"No"},{key:'Existing Products', value:'Internet 150, PikTv, ADT Security, Pik Tv'},{key:'Available Technology', value:'Copper'},{key:'COID', value:'AB87H90'},{key:'FSA', value:'4081A'},{key:'Drop Permission Collected', value:'Yes'},{key:'Drop Constructed', value:'Yes'},{key:'Sell Decision', value:'Book Order'}, ], dropRequired:true,selfInstall:false,westernPremise:true, dueDate:"May 18th"},
  {lat:49.22978, lng:-123.1599,address:'132 Address Road, Vancouver, BC (MDU)', notes:'Drop not required', existingCustomer:true, products: ['unavail','unavail','no','unavail','TELUS','no','LWC',], copperCurrent:false, fibreCurrent:true,copperAvail:false, fibreAvail:true, ruralTech:false, icon:'SK', knocks:[{value:'No One Home', date:'1/09/2021', time:'4:18 PM'},{value:'Come Back', date:'1/3/2022', time:'11:23 AM'},{value:'Covid', date:'1/23/2022', time:'1:17 PM'}],details:[{key: "Telus Rewards",value:"Yes"}, {key: "My Care",value:"No"}, {key: "L&R Promos",value:"Yes"}, {key: "Cancelled Previous Services",value:"No"},{key:'Existing Products', value:'Internet 150, Mobility, Telus Smart Home Security, Online Security'},{key:'Available Technology', value:'Copper'},{key:'COID', value:'AB87H90'},{key:'FSA', value:'4081A'},{key:'Drop Permission Collected', value:'Yes'},{key:'Drop Constructed', value:'Yes'},{key:'Sell Decision', value:'Book Order'}, ], dropRequired:false,selfInstall:true,westernPremise:false, dueDate:"May 23rd"},
  {lat:49.22966, lng:-123.1599,address:'133 Address Road, Vancouver, BC (SFU)', notes:'Wants fibre internet when available', existingCustomer:false, products: ['unavail','unavail','no','unavail','KODOO','no','no',], copperCurrent:false, fibreCurrent:false,copperAvail:false, fibreAvail:false, ruralTech:false, icon:'OK', knocks:[{value:'No One Home', date:'1/09/2021', time:'4:18 PM'},{value:'Come Back', date:'1/3/2022', time:'11:23 AM'},{value:'Covid', date:'1/23/2022', time:'1:17 PM'}],details:[{key: "Telus Rewards",value:"Yes"}, {key: "My Care",value:"No"}, {key: "L&R Promos",value:"Yes"}, {key: "Cancelled Previous Services",value:"No"},{key:'Existing Products', value:'None'},{key:'Available Technology', value:'None'},{key:'COID', value:'AB87H90'},{key:'FSA', value:'4081A'},{key:'Drop Permission Collected', value:'Yes'},{key:'Drop Constructed', value:'Yes'},{key:'Sell Decision', value:'Book Order'}, ], dropRequired:false,selfInstall:false,westernPremise:false, dueDate:"March 23rd"},
  {lat:49.22978, lng:-123.1592,address:'134 Address Road, Vancouver, BC (SXU)', notes:'Follow up Feb 23rd', existingCustomer:true, products: ['unavail','unavail','ADT','unavail','Public','no','no',], copperCurrent:false, fibreCurrent:true,copperAvail:false, fibreAvail:true, ruralTech:false, icon:'NOH', knocks:[{value:'No One Home', date:'1/09/2021', time:'4:18 PM'},{value:'Come Back', date:'1/3/2022', time:'11:23 AM'},{value:'Covid', date:'1/23/2022', time:'1:17 PM'}],details:[{key: "Telus Rewards",value:"Yes"}, {key: "My Care",value:"No"}, {key: "L&R Promos",value:"Yes"}, {key: "Cancelled Previous Services",value:"No"},{key:'Existing Products', value:'Optik Tv '},{key:'Available Technology', value:'Copper'},{key:'COID', value:'AB87H90'},{key:'FSA', value:'4081A'},{key:'Drop Permission Collected', value:'Yes'},{key:'Drop Constructed', value:'Yes'},{key:'Sell Decision', value:'Book Order'}, ], dropRequired:false,selfInstall:false,westernPremise:false, dueDate:"May 1st"},
  {lat:49.22966, lng:-123.1592,address:'135 Address Road, Vancouver, BC (MDU)', notes:'COVID flyer, Dangeous walkway', existingCustomer:true, products: ['no','Pik','TELUS','no','KOODO','no','no',], copperCurrent:true, fibreCurrent:false,copperAvail:true, fibreAvail:false, ruralTech:false, icon:'DNK', knocks:[{value:'No One Home', date:'1/09/2021', time:'4:18 PM'},{value:'Come Back', date:'1/3/2022', time:'11:23 AM'},{value:'Covid', date:'1/23/2022', time:'1:17 PM'}],details:[{key: "Telus Rewards",value:"Yes"}, {key: "My Care",value:"No"}, {key: "L&R Promos",value:"Yes"}, {key: "Cancelled Previous Services",value:"No"},{key:'Existing Products', value:'Internet 150, Home Phone, PikTv '},{key:'Available Technology', value:'Copper'},{key:'COID', value:'AB87H90'},{key:'FSA', value:'4081A'},{key:'Drop Permission Collected', value:'Yes'},{key:'Drop Constructed', value:'Yes'},{key:'Sell Decision', value:'Book Order'}, ], dropRequired:false,selfInstall:false,westernPremise:true, dueDate:"May 9th"},
  {lat:49.22990, lng:-123.1592,address:'137 Address Road, Vancouver, BC (MDU)', notes:'Needs better internet according to neighbours', existingCustomer:true, products: ['WHSIA','Optik','Public','no','no','TOS','LWC',], copperCurrent:false, fibreCurrent:false,copperAvail:false, fibreAvail:false, ruralTech:true, icon:'house', knocks:[{value:'No One Home', date:'1/09/2021', time:'4:18 PM'},{value:'Come Back', date:'1/3/2022', time:'11:23 AM'},{value:'Covid', date:'1/23/2022', time:'1:17 PM'}],details:[{key: "Telus Rewards",value:"Yes"}, {key: "My Care",value:"No"}, {key: "L&R Promos",value:"Yes"}, {key: "Cancelled Previous Services",value:"No"},{key:'Existing Products', value:'Internet 150, Home Phone, PikTv '},{key:'Available Technology', value:'Copper'},{key:'COID', value:'AB87H90'},{key:'FSA', value:'4081A'},{key:'Drop Permission Collected', value:'Yes'},{key:'Drop Constructed', value:'Yes'},{key:'Sell Decision', value:'Book Order'}, ], dropRequired:false,selfInstall:false,westernPremise:true, dueDate:"May 7th"},
  {lat:49.23002, lng:-123.1592,address:'139 Address Road, Vancouver, BC (MDU)', notes:'Likes the best products', existingCustomer:true, products: ['HS1GB','Pik','Public','no','no','ADT','LWC',], copperCurrent:false, fibreCurrent:true,copperAvail:true, fibreAvail:true, ruralTech:false, icon:'hot', knocks:[{value:'No One Home', date:'1/09/2021', time:'4:18 PM'},{value:'Come Back', date:'1/3/2022', time:'11:23 AM'},{value:'Covid', date:'1/23/2022', time:'1:17 PM'}],details:[{key: "Telus Rewards",value:"Yes"}, {key: "My Care",value:"No"}, {key: "L&R Promos",value:"Yes"}, {key: "Cancelled Previous Services",value:"No"},{key:'Existing Products', value:'Internet 150, Home Phone, PikTv '},{key:'Available Technology', value:'Copper'},{key:'COID', value:'AB87H90'},{key:'FSA', value:'4081A'},{key:'Drop Permission Collected', value:'Yes'},{key:'Drop Constructed', value:'Yes'},{key:'Sell Decision', value:'Book Order'},], dropRequired:false,selfInstall:false,westernPremise:true, dueDate:"May 7th"},
]

if(userFrame.houseVariablesInitialized == false){
  // initialize the redux store with house details, for each house
  for(var i = 0; i < houses.length; i++){
    
    // only initialize the variables once, to prevent overwriting changes back to defaults
    if(houses[i].initialized==false){
      
      const h = houses[i]
      
      h.latitude = markers[i].lat;
      h.longitude = markers[i].lng;
      h.address = markers[i].address;
      h.notes = markers[i].notes;
      h.existingProducts = markers[i].products;
      h.copperCurrent = markers[i].copperCurrent;
      h.fibreCurrent = markers[i].fibreCurrent;
      h.copperAvail = markers[i].copperAvail;
      h.fibreAvail = markers[i].fibreAvail;
      h.icon = markers[i].icon;
      h.details = markers[i].details;
      h.knockHistory = markers[i].knocks;
      h.expand = false;
      h.dropRequired = markers[i].dropRequired;
      h.selfInstall = markers[i].selfInstall;
      h.existingCustomer = markers[i].existingCustomer;
      h.initialized = true;
      h.ruralTech = markers[i].ruralTech;
      h.westernPremise = markers[i].westernPremise;
      h.dueDate = markers[i].dueDate;
      dispatch(updateHouse(h))
    }

  }
  const u = userFrame
  userFrame.houseVariablesInitialized = true;
  dispatch(updateUserFrame(u))
}



}

  function showPremiseMarkers(){
      
    if(userFrame.houseVariablesInitialized){
      
      // map the data to the marker component to display
      const markerComponents = houses.map((house,index)=>
        <OverlayView 
        key={index}
        position={{lat:house.latitude,
        lng:house.longitude}}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
            <Marker
            id={index+1}
            icon={house.icon!=undefined ? house.icon : 'house'}
              />
        </OverlayView>
      )

      return markerComponents
    }

  }

  function showStickMan() {
    return(
      <OverlayView 
      position={{lat:49.23040, lng:-123.1601}}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
        <StickMan/>
      </OverlayView>
    )
}

function showLocationMarker() {
  {/* Location */}
  return(
    <OverlayView
    position={{lat: userFrame.locationLat, lng: userFrame.locationLng}}
    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
          <LocationMarker
            lat={currentLat}
            lng={currentLong}
          /> 
    </OverlayView>
  )
  
}

    /*
  This function gets all the houses (waypoints) in between the destination and the origin. It is 
  used by the calculateRoute() function to ensure passing through all homes in the walklist.
  */
  function getWayPoints(origin, destination){
    
    let wayPoints = []
    for(var i = 0; i < houses.length; i++){
      // check if the house should be a waypoint. starting and ending houses should not be waypoints
      if(
      !(houses[i].latitude == origin.lat && houses[i].longitude == origin.lng) 
      &&
      !(houses[i].latitude == destination.lat && houses[i].longitude == destination.lng)){
        
        wayPoints = [...wayPoints,{location:{lat:houses[i].latitude, lng:houses[i].longitude}, stopover:true}]

      }
    }
    return wayPoints
  }

    /*
  This function uses the Google Directions API to find the optimal route between an origin and a 
  destination. It ensures that the route passes through all waypoint (all houses in walk list)
  */
  async function calculateRoute() {
    
    const directionsService = new window.google.maps.DirectionsService()
    const results = await directionsService.route({
      origin: {lat:49.23040, lng:-123.1601},
      destination: {lat:49.23002, lng:-123.1592},
      waypoints: getWayPoints({lat:49.23040, lng:-123.1601},{lat:49.23002, lng:-123.1592}),
      optimizeWaypoints: true,
      travelMode: window.google.maps.TravelMode.WALKING
    })
    setDirectionsResponse(results)
    
    /*
    // get route data
    setDistance(results.routes[0].legs[0].distance.text)
    setDuration(results.routes[0].legs[0].duration.text)
    
    for(let i = 0; i <results.routes[0].legs.length; i++){
      console.log(results.routes[0].legs[i].distance.text, results.routes[0].legs[i].duration.text)
    }*/

  }

    return (
      // Important! Always set the container height explicitly
      <div  key={userFrame.darkMode?1:(userFrame.drawer==2?2:0)} style={{ height: userFrame.drawer==0?'100%':'100%', width: '100%'}}>
        <GoogleMap
            mapContainerStyle={{width:'100vw', height:userFrame.houseId==0?'90vh':(userFrame.drawer==2?(width>650?"65vh":"50vh"):'10vh')}}
            center={
              {lat: userFrame.houseId !=0 && !userFrame.locationButtonClicked ? houses[userFrame.houseId-1].latitude : userFrame.defaultMapLat, 
              lng: userFrame.houseId !=0 && !userFrame.locationButtonClicked ? houses[userFrame.houseId-1].longitude : userFrame.defaultMapLng}}
              zoom={userFrame.zoomMap}
            onLoad={onLoad}
            onUnmount={onUnmount}
            options={{
              styles: userFrame.darkMode ? darkMap.dark : [],
              streetViewControl: userFrame.streetView?true:false,
              mapTypeControl: false,
            }}
          >
            <>
            {showStickMan()}
            {showPremiseMarkers()}
            {showLocationMarker()}
            {directionsResponse 
            && 
            <DirectionsRenderer directions={directionsResponse} options={{
              polylineOptions: {
                  strokeOpacity: 0.7,
                  strokeColor: userFrame.darkMode?'white':'#2B8000',
                  strokeWeight:6
              },
              suppressMarkers: true}}/>}
            </>
        </GoogleMap>
      </div>
    );
  
}

export default MapContainer;